// React Imports
import { FC } from "react";

import {
  Box,
  Grid,
  InputAdornment,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import "./ViewJob.css";

// Functional Imports
import htmlParser from "html-react-parser";
import Functions from "../../utils/Functions";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";

// Component Imports
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";

interface JobdetailProps {
  jobdata?: any;
  skilldata?: any;
}

const Jobdetail: FC<JobdetailProps> = (props) => {
  return (
    <Box data-testid="view-job-detail" className="view-job-detail">
      <Box
        sx={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text fontWeight={700} fontSize={16}>
          {STRINGS.CREATE_JOB.REQUIRED_DETAIL}
        </Text>
      </Box>
      <Box
        className="detailsection"
        sx={{
          width: "100%",
          mb: 8,
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.JOB_TITLE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "jobTitle",
                name: "jobTitle",
                value: props.jobdata?.jobTitle,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.NUMBER_OF_OPENING}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "Number-Openings",
                name: "numberOfOpenings",
                value: props.jobdata?.numberOfOpening,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.ZIP}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "Zip",
                name: "zipcode",
                value: props.jobdata?.zipCode,
              }}
            />
          </Grid>
         
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.EMPLOYMENT_TYPE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "jobType",
                name: "jobType",
                value: Functions.ValueRetrieve.getJobType(
                  props.jobdata?.jobType
                ),
              }}
            />
          </Grid>

          {(props.jobdata?.jobType == "C" || props.jobdata?.jobType == "R") && (
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={STRINGS.CREATE_JOB.BILL_RATE}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        sx={{ fontWeight: 700, color: Colors.Black1 }}
                      >
                        $
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    readonly: true,
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  id: "bill-rate",
                  name: "billRate",
                  value: props.jobdata?.billRatePckg?.billRate,
                }}
              />
            </Grid>
          )}
          {(props.jobdata?.jobType == "F" || props.jobdata?.jobType == "R") && (
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={STRINGS.CREATE_JOB.ANNUALSALARY}
                inputProps={{
                  readonly: true,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ fontWeight: 700, color: Colors.Black1 }}
                    >
                      <Typography
                        sx={{ fontWeight: 700, color: Colors.Black1 }}
                      >
                        $
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  id: "annual-salary",
                  name: "annualSalary",
                  value: props.jobdata?.package?.packageRange?.range,
                }}
              />
            </Grid>
          )}
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.INTERVIEW_MODE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "interviewMode",
                name: "interviewMode",
                value: Functions.ValueRetrieve.getInterviewType(
                  props.jobdata?.interviewMode
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.MODE_OF_WORK}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "modeofwork",
                name: "modeofwork",
                value: Functions.ValueRetrieve.getModeofWork(
                  props.jobdata?.modeofWork
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={STRINGS.CREATE_JOB.SKILL_SET}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                id: "skillset",
                name: "skillset",
                value: props.skilldata?.skills,
              }}
            />
          </Grid>
        
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid item xs={6} md={6} mt={2}>
            <Text fontWeight={700} fontSize={16}>
              {STRINGS.CREATE_JOB.JOD_DESCRIPTION}
            </Text>
            <Box
              sx={{
                display: "flex",
                gap: 0.3,
                justifyContent: "space-between",
                mb: 1,
                mt: 3,
              }}
            >
              <Text color={Colors.Grey9}>{"Description"}</Text>

            
              </Box>

            <Card variant="outlined" sx={{ Width: "100%",height:"450px",overflow:'auto', mb: 3, mt: 2 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {htmlParser(
                    props.jobdata?.jobDesc ? props.jobdata?.jobDesc.replace('<p><br>&nbsp;</p>','') : ""
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Jobdetail;

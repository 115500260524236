// React Imports
import { FC, ReactNode } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface ProfileActionTileProps {
  sx?: SxProps;
  icon?: ReactNode;
  label?: string;
  textColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
}

const ProfileActionTile: FC<ProfileActionTileProps> = (props) => {
  return (
    <ButtonBase
      data-testid={props.testId}
      onClick={props.onClick}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: 50,
        width: "100%",
        p: 2.5,
        gap: 2,
        boxShadow: 1,
        // borderBottom: "0.1px solid " + Colors.Grey,
        ...props.sx,
      }}
    >
      {props.icon}
      <Text fontSize={16} color={props.textColor} fontWeight={500}>
        {props.label}
      </Text>
    </ButtonBase>
  );
};

export default ProfileActionTile;

ProfileActionTile.defaultProps = {
  textColor: Colors.Black1,
};

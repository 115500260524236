// React Imports
import { FC } from "react";

// UI Imports
import { Box, IconButton, SxProps } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface FilterTileProps {
  title?: string;
  onClick?: any;
  sx?: SxProps;
}

const FilterTile: FC<FilterTileProps> = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: Colors.Grey7,
        height: 30,
        px: 1.5,
        py: 0.5,
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: 2,
        ...props.sx,
      }}
    >
      <Text>{props.title}</Text>
      <IconButton
        onClick={props.onClick}
        sx={{
          height: 24,
          width: 24,
          p: 0,
        }}
      >
        <IoIosClose />
      </IconButton>
    </Box>
  );
};

export default FilterTile;

// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Popover } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsThreeDotsVertical } from "react-icons/bs";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionJobs from "./HoverActionJobs";
import ENUMS from "../../utils/Enums";
import API from "../../api/API";

interface ActionTileJobListProps extends Partial<GridRenderCellParams> {
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
  jobListLoading?: boolean;
  setJobListLoading?: any;

  isTesting?: boolean;
}

const ActionTileJobList: FC<ActionTileJobListProps> = (props) => {
  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (props.isTesting) {
      const param: any = {};
      handleClick(param);
      toggleStatus();
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const toggleStatus = () => {
    let newStatus = "";

    if (props.row?.jobStatus === ENUMS.JOB_STATUS_TYPES.ACTIVE) {
      newStatus = ENUMS.JOB_STATUS_TYPES.INACTIVE;
    } else {
      newStatus = ENUMS.JOB_STATUS_TYPES.ACTIVE;
    }

    props.setJobListLoading(true);
    setHoverActionsEnabled(false);
    API.updateJobStatus(props.row?.id, newStatus)
      .then((response) => {
        props.setUpdateDataAgain(!props.updateDataAgain);
      })
      .catch((e) => {
        props.setJobListLoading(false);
      });
  };

  return (
    <Box
      data-testid="action-tile-job-list"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
          backgroundColor: hoverActionsEnabled ? Colors.Grey12 : null,
        }}
        data-testid="menu-btn"
        onClick={(ev) => handleClick(ev)}
      >
        <BsThreeDotsVertical size={20} color={Colors.Black1} />
      </IconButton>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={() => setHoverActionsEnabled(false)}
        anchorOrigin={{
          vertical: 40,
          horizontal: 10,
        }}
      >
        <HoverActionJobs
          id={props.row?.id}
          status={props.row?.jobStatus}
          onClickActiveInactive={toggleStatus}
        />
      </Popover>
    </Box>
  );
};

export default ActionTileJobList;

enum FILTERS {
  NAME = "Name",
  LOCATION = "Location",
  STATUS = "Status",
  JOB_ID = "Job ID",
  EXPERIENCE = "Experience",
}

enum JOB_FILTERS {
  JOB_TITLE = "Job Title",
  JOB_LOCATION = "Job Location",
  EMPLOYMENT_TYPE = "Employment Type",
  STATUS = "Status",
  HIRING_MANAGER = "Hiring Manager",
  NO_OF_APPLICATION = "No.of Applications",
  JOB_POSTED_DATE = "Job Posted Date",
}

enum APPLICATION_STATUS_TYPES {
  REJECTED = "Rejected",
  HIRED = "Hired",
  INTERVIEW_REQUESTED = "Interview Requested",
  PENDING_REVIEW = "Pending Review",
  INTERVIEWED = "Interviewed",
  INTERVIEW_SCHEDULED = "Interview Scheduled",
}

enum JOB_STATUS_TYPES {
  IN_REVIEW = "In Review",
  INACTIVE = "Inactive",
  ACTIVE = "Active",
}

enum CONTACT_US_TYPES {
  CREATE_PASSWORD_EXPIRED = "cp",
  RESET_PASSWORD_EXPIRED = "rp",
  NONE = "n",
}

const ENUMS = {
  FILTERS,
  JOB_FILTERS,
  APPLICATION_STATUS_TYPES,
  JOB_STATUS_TYPES,
  CONTACT_US_TYPES,
};

export default ENUMS;

// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  IoGridOutline,
  IoDocumentTextOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { BsBriefcase } from "react-icons/bs";
import { FaPeopleArrows } from "react-icons/fa";

// Functional Imports
import { useLocation, useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import SideBarTile from "./SideBarTile";

interface SideBarProps {}

const SideBar: FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      data-testid="sidebar-comp"
      sx={{
        backgroundColor: Colors.Blue7,
        minWidth: 70,
        height: "100%",
      }}
    >
      <SideBarTile
        label="Menu"
        icon={<RxHamburgerMenu size={22} color={Colors.White} />}
      />
      <SideBarTile
        label="Menu"
        icon={<IoGridOutline size={22} color={Colors.White} />}
      />
      <SideBarTile
        label="Jobs"
        icon={<BsBriefcase size={22} color={Colors.White} />}
        onClick={() => {
          navigate(Paths.JOBS);
        }}
        isActive={location.pathname.includes(Paths.JOBS)}
      />
      <SideBarTile
        isActive={location.pathname.includes(Paths.APPLICATIONS)}
        label="Application"
        icon={<IoDocumentTextOutline size={22} color={Colors.White} />}
        onClick={() => {
          navigate(Paths.APPLICATIONS);
        }}
      />
      <SideBarTile
        label="Menu"
        icon={<FaPeopleArrows size={22} color={Colors.White} />}
      />
      <SideBarTile
        label="Menu"
        icon={<IoPersonOutline size={22} color={Colors.White} />}
        
      />
    </Box>
  );
};

export default SideBar;

import dayjs from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import STRINGS from '../../utils/Strings';

const CustomDay = (props: PickersDayProps<dayjs.Dayjs> & { selectedDates: Set<string>, currentDate: dayjs.Dayjs }) => {
  const { day, outsideCurrentMonth, selectedDates, currentDate, ...other } = props;
  const isToday = day.isSame(currentDate, 'day');
  const isSelected = selectedDates.has(day.format(STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT));

  let border = 'none';

  if (isSelected) {
    border = `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR}`;
  }

  const hoverBorder = isSelected ? `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR}` : 'none';
  const color = isSelected ? STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR : 'inherit';

  

  return (
    <PickersDay
      {...other}
      day={day}
      disabled={day.isBefore(currentDate, 'day')}
      outsideCurrentMonth={outsideCurrentMonth}
      data-testid={`day-${day.format('YYYY-MM-DD')}`}
      sx={{
        border: `${border} !important`,
        borderRadius: '50%',
        '&:hover': {
          border: `${hoverBorder} !important`,
        },
        color,
        backgroundColor: isToday ? "#D7DBEF" : undefined,
        '&.MuiPickersDay-root': {
          color: isToday ? "#000" : undefined,
          fontWeight: isSelected ? 700 : 400,
          fontSize: 14,
        },
        '&.Mui-selected' : {
          backgroundColor: "#4A57A3 !important ",
          color: "#fff"
        },
        '&.Mui-disabled:not(.Mui-selected)' : {
          color: isSelected ? "#4A57A3" : "#b0b0b0",
        },
      }}
    />
  );
};

export default CustomDay;

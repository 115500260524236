// React Imports
import { FC, ReactNode } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";

// Component Imports
import AboutFooter from "../../common/AboutFooter";

interface LogoTaglineChildProps {
  children: ReactNode;
  sx?: SxProps;
  whiteBoxSx?: SxProps;
  leftInnerSx?: SxProps;
  rightInnerSx?: SxProps;
  testId?: string;
}

const LogoTaglineChild: FC<LogoTaglineChildProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${Images.BackgroundLogin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 450,
          width: 800,
          // height: "70%",
          // width: "60%",
          backgroundColor: Colors.White,
          borderRadius: 7,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...props.whiteBoxSx,
        }}
      >
        <Box
          sx={{
            width: "40%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid " + Colors.Grey16,
            ...props.leftInnerSx,
          }}
        >
          <Box
            component={"img"}
            src={Images.LogoTagline}
            sx={{
              width: "80%",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "60%",
            ...props.rightInnerSx,
          }}
        >
          {props.children}
        </Box>
      </Box>
      <AboutFooter />
    </Box>
  );
};

export default LogoTaglineChild;

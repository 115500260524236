// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import Colors from "../../utils/Colors";

// Component Imports
import LogoTaglineChild from "../../common/LogoTaglineChild";
import BlueButton from "../../common/BlueButton";
import Text from "../../common/Text";
import Paths from "../../routes/Paths";
import ENUMS from "../../utils/Enums";

interface ContactUsProps {
  isTesting?: boolean;
}

const ContactUs: FC<ContactUsProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { type } = params;

  return (
    <LogoTaglineChild
      testId="contact-us-page"
      whiteBoxSx={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      leftInnerSx={{
        border: 0,
        height: "40%",
      }}
      rightInnerSx={{
        // width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          gap: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {(type === ENUMS.CONTACT_US_TYPES.CREATE_PASSWORD_EXPIRED ||
          type === ENUMS.CONTACT_US_TYPES.RESET_PASSWORD_EXPIRED ||
          props.isTesting) && (
          <Text
            fontSize={18}
            fontWeight={700}
            style={{
              textAlign: "center",
            }}
          >
            {type === ENUMS.CONTACT_US_TYPES.CREATE_PASSWORD_EXPIRED
              ? "The create password link has expired."
              : "The reset password link has expired."}
          </Text>
        )}
        {(type === ENUMS.CONTACT_US_TYPES.CREATE_PASSWORD_EXPIRED ||
          type === ENUMS.CONTACT_US_TYPES.NONE ||
          props.isTesting) && (
          <>
            <Text
              fontSize={18}
              style={{
                textAlign: "center",
              }}
            >
              Please write to us on{" "}
              <span
                data-testid="contact-us-btn"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: Colors.Blue7,
                  fontWeight: 700,
                }}
                onClick={() =>
                  (window.location.href = "mailto:contact@velocitymsp.ai")
                }
              >
                contact@velocitymsp.ai
              </span>
            </Text>

            <Text
              fontSize={18}
              style={{
                textAlign: "center",
                marginBottom: 24,
              }}
            >
              We typically respond within 24 hours on all business days.
            </Text>
          </>
        )}
        {(type === ENUMS.CONTACT_US_TYPES.RESET_PASSWORD_EXPIRED ||
          type === ENUMS.CONTACT_US_TYPES.NONE ||
          props.isTesting) && (
          <BlueButton
            testId="go-to-homepage-btn"
            onClick={() => navigate(Paths.LOGIN)}
            title="Go to Homepage"
            sx={{
              width: 180,
              mt: type === ENUMS.CONTACT_US_TYPES.NONE ? 0 : 8,
            }}
          />
        )}
      </Box>
    </LogoTaglineChild>
  );
};

export default ContactUs;

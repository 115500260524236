// React Imports
import { FC } from "react";

import { Box, Grid } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";

interface AppdetailProps {
  appData?: any;
}

const Appdetail: FC<AppdetailProps> = (props) => {
  return (
    <Box
      data-testid="view-application-detail"
      className="view-application-detail"
    >
      <Box
        sx={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text fontWeight={700} fontSize={16}>
          {"Basic Details"}
        </Text>
      </Box>
      <Box
        className="detailsection"
        sx={{
          width: "100%",
          mb: 8,
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Current Job Title"}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    color: Colors.Black1,
                    backgroundColor: Colors.White,
                    fontWeight: 700,
                    fontSize: 16,
                  },
                },
                id: "jobTitle",
                name: "jobTitle",
                value: props.appData?.currentJobTitle,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Experience"}
              textFieldProps={{
                variant: "standard",
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    color: Colors.Black1,
                  },
                },
                name: "experience",
                id: "experience",
                value: props.appData?.experience
                  ? props.appData?.experience + " Years"
                  : "",
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Primary Skills"}
              textFieldProps={{
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                variant: "standard",
                inputProps: {
                  sx: {
                    fontSize: 16,
                    color: Colors.Black1,
                    fontWeight: 700,
                    backgroundColor: Colors.White,
                  },
                  readonly: true,
                },
                name: "primaryskills",
                id: "primaryskills",
                value: props.appData?.primarySkills,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Open to Relocation"}
              textFieldProps={{
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                variant: "standard",
                inputProps: {
                  readonly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    color: Colors.Black1,
                    fontWeight: 700,
                    fontSize: 16,
                  },
                },
                name: "Relocation",
                id: "Relocation",
                value: props.appData?.canRelocate ? "Yes" : "No",
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Work Authorization"}
              textFieldProps={{
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                variant: "standard",
                inputProps: {
                  readonly: true,
                  sx: {
                    fontWeight: 700,
                    fontSize: 16,
                    color: Colors.Black1,
                    backgroundColor: Colors.White,
                  },
                },
                name: "Workauthorization",
                id: "Workauthorization",
                value: props.appData?.workAuthorization,
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} mt={3}>
            <LabelInput
              label={"Location"}
              textFieldProps={{
                sx: {
                  width: { xs: "20%", sm: 275 },
                },
                variant: "standard",
                inputProps: {
                  sx: {
                    fontWeight: 700,
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    color: Colors.Black1,
                  },
                  readonly: true,
                },
                id: "location",
                name: "location",
                value: props.appData?.location,
              }}
            />
          </Grid>

          <Grid item xs={3} md={3} mt={3}></Grid>
          <Grid item xs={3} md={3} mt={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Appdetail;

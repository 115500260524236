// React Imports
import { FC } from "react";

// UI Imports
import {
  Box,
  SxProps,
  FormHelperText,
  MenuItem,
  FormControl,
  InputBaseComponentProps,
} from "@mui/material";
import Colors from "../../utils/Colors";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Component Imports
import Text from "../Text";

// Functional Imports

// Component Imports

interface LabelSelectborderProps {
  parentTestId?: string;
  label: string;
  options: any[];
  setVal?: any;
  required?: boolean;
  sx?: SxProps;
  placeholder?: string;
  onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
  id?: string;
  selectFieldProps?: any;
  accessDisplayValue?: string;
  accessValue?: string;
  name?: string;
  textFieldProps?: InputBaseComponentProps;
  error?: boolean;
  helperText?: string | false;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const LabelSelectborder: FC<LabelSelectborderProps> = (props) => {
  const renderPlaceholder = (selected: any) => {
    if (!selected || selected.length === 0) {
      return <span style={{ color: Colors.Grey6 }}>{props.placeholder}</span>;
    }
    if (!props.accessValue || !props.accessDisplayValue) {
      return selected;
    }
    const selectedOption = props.options.find(
      (item) => item[props.accessValue!] === selected
    );
    return selectedOption ? selectedOption[props.accessDisplayValue] : selected;
  };

  return (
    <Box data-testid={props.parentTestId}>
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={Colors.Grey9}>{props.label}</Text>
        {props.required && <Text color={Colors.Red}>*</Text>}
      </Box>

      <FormControl sx={props.sx}>
        <Select
          id={props.id}
          value={props.setVal}
          onChange={props.onChange}
          onBlur={props.handleBlur}
          displayEmpty
          renderValue={renderPlaceholder}
          inputProps={{
            "data-testid": props.parentTestId,
            id: props.id,
            name: props.name,
            "aria-label": "Without label",
          }}
          textFieldProps={props.textFieldProps}
          size="small"
          sx={{
            fontFamily: "Roboto",
            fontSize: 16,
            width: "100%",
          }}
          {...props.selectFieldProps}
        >
          {props.options.map((item, index) => {
            let displayValue = props.accessDisplayValue
              ? item[props.accessDisplayValue]
              : item;
            let value = props.accessValue ? item[props.accessValue] : item;
            return (
              <MenuItem key={item} value={value}>
                {displayValue}
              </MenuItem>
            );
          })}
        </Select>
        {props.error && (
          <FormHelperText error>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default LabelSelectborder;

// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Modal, Paper } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import STRINGS from "../../utils/Strings";

// Component Imports
import ReplyEmailInput from "./ReplyEmailInput";
import BlueButton from "../../common/BlueButton";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";
import API from "../../api/API";
import { useFormik } from "formik";
import Functions from "../../utils/Functions";
import { array, object, string } from "yup";
import Toasts from "../../utils/Toasts";
import LabelInputChipset from "../../common/LabelInputChipst";
import "./SharePopup.css";
import CONSTANTS from "../../utils/Constants";
import Colors from "../../utils/Colors";

interface SharePopupProps {
  open: boolean;
  setOpen: any;
  shareDetails?: any;

  isTesting?: boolean;
}

const SharePopup: FC<SharePopupProps> = (props) => {
  const [sendingFailure, setSendingFailure] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: [],
      replyTo: "",
    },
    enableReinitialize: true,
    validationSchema: object({
      email: array()
        .required("Email is required")
        .min(1, "Email ID is required"),
      replyTo: string().email("Please enter valid email ID"),
    }),
    onSubmit: (values) => {
      onConfirm(values);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      const initTestValues: any = {
        email: ["test@test.com"],
        replyTo: "test@test.com",
      };
      formik.setValues(initTestValues).then((response) => {
        formik.handleSubmit();
      });
      handleEmailChange([]);
      handleEmailChange(["test"]);
      handleEmailChange(["test@test.com"]);
      handleClose();
      onConfirm({
        email: ["test@test.com"],
        replyTo: "test@test.com",
      });
    }
  }, []);

  const onConfirm = (values: any) => {
    const payload = {
      jobId: Number(props.shareDetails?.jobId),
      jobTitle: props.shareDetails?.jobTitle,
      jobDescription: props.shareDetails?.jobDescription,
      emailIds: values.email,
      replyTo: values.replyTo,
      candidateDocId: props.shareDetails?.candidateDocId,
    };

    setSendingFailure(false);
    API.shareApplicationEmail(payload)
      .then((response) => {
        if (response.isError) {
          Toasts.error(
            "Please enter an email address within your organization's official domain for the reply to field."
          );
        } else {
          if (
            response.message?.appStatusDescription?.includes(
              "Email couldn't sent to"
            )
          ) {
            const emailNonSends =
              response.message?.appStatusDescription?.split(":");

            let allEmailNonSends = emailNonSends[1].split(",");

            allEmailNonSends = allEmailNonSends.map((item: string) =>
              item.trim()
            );

            formik.setFieldValue("email", allEmailNonSends);

            setSendingFailure(true);
          } else {
            Toasts.success("Application is shared successfuly.");
            props.setOpen(false);
            formik.resetForm();
          }
        }
      })
      .catch((e) => {
        Toasts.error(
          "Please enter an email address within your organization's official domain."
        );
      });
  };

  const handleEmailChange = (ev: any) => {
    if (ev.length < formik.values.email.length) {
      formik.setFieldValue("email", ev);
      setSendingFailure(false);
    } else {
      const lastElement: string = ev[ev.length - 1];

      const isValidEmail = CONSTANTS.REGEX.EMAIL.test(lastElement);

      if (isValidEmail) {
        formik.setFieldValue("email", ev);
        setSendingFailure(false);
      } else {
        Toasts.error("Enter valid email");
      }
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setSendingFailure(false);
    props.setOpen(false);
  };

  return (
    <Modal
      className="share-popup"
      open={props.open}
      onClose={handleClose}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="share-popup"
        sx={{
          p: 4,
          border: "none",
          width: 600,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            {STRINGS.SHARE_POPUP.SHARE_APPLICATION}
          </Text>
          <IconButton data-testid="close-btn" onClick={handleClose}>
            <IoIosClose />
          </IconButton>
        </Box>

        <LabelInputChipset
          required
          label={"Email ID"}
          sx={{
            width: "100%",
            mt: 1,
            maxHeight: 40,
          }}
          tags={formik.values.email}
          onChange={handleEmailChange}
          maxLength={2000}
        />
        {formik.errors.email && (
          <Text
            style={{
              marginTop: 32,
            }}
            color={Colors.Red}
            fontSize={12}
          >
            {formik.errors.email}
          </Text>
        )}
        

        <ReplyEmailInput
          sx={{
            mt: formik.errors.email ? 1 : 4,
          }}
          textFieldProps={{
            inputProps: {
              "data-testid": "reply-to-input",
              style: {
                backgroundColor: "#f9fafc"
              }
            },
            id: "replyTo",
            name: "replyTo",
            value: formik.values.replyTo,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formik.touched?.replyTo,
              Boolean(formik.errors?.replyTo)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formik.touched?.replyTo,
              formik.errors?.replyTo
            ),
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Text color={Colors.Red}>
            {sendingFailure ? "Cannot send emails to the above email IDs" : ""}
          </Text>
          <BlueButton
            testId="share-btn"
            onClick={() => {
              formik.handleSubmit();
            }}
            title={STRINGS.SHARE_POPUP.SHARE}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default SharePopup;

// React Imports
import { FC,useState } from 'react';

// UI Imports
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './ViewJob.css';
import Jobdetail from './Jobdetail';
import Jobapplication from '../EditJob/Jobapplication';

// Functional Imports

// Component Imports

interface TabsectionProps {
  jobdata?:any;
  skilldata?:any;
  appjobid?:any;
}

const Tabsection: FC<TabsectionProps> = (props) => {
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%', mt:2 }} className='editdetailtab'>
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange}>
          <Tab label="Job Details" value="1" />
          <Tab label="Applications" value="2" />
        </TabList>
      </Box>

    <TabPanel sx={{background:'#fff',mr:2 }} value="1"><Jobdetail jobdata={props.jobdata} skilldata={props.skilldata}/></TabPanel>
    <TabPanel sx={{background:'#fff'}}  value="2"><Jobapplication appjobid={props.appjobid}/></TabPanel>
    </TabContext>
  </Box>
  );
};

export default Tabsection;
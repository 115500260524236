// React Imports
import { FC } from "react";

// UI Imports
import { Box, Switch, SwitchProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface ActiveJobsSwitchProps {
  label?: string;
  switchProps?: SwitchProps;

  testId?: string;
}

const ActiveJobsSwitch: FC<ActiveJobsSwitchProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: 2,
      }}
    >
      <Text fontSize={16}>{props.label}</Text>
      <Switch
        inputProps={{
          //@ts-ignore
          "data-testid": props.testId,
        }}
        sx={{
          "&.MuiSwitch-root .Mui-checked": {
            color: Colors.Blue7,
          },
        }}
        {...props.switchProps}
      />
    </Box>
  );
};

export default ActiveJobsSwitch;

// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase, Switch } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports
import { useNavigate } from "react-router-dom";
import API from "../../api/API";
import ENUMS from "../../utils/Enums";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import FilterPopupJobs from "./FilterPopupJobs";
import Breadcrumbs from "../../common/Breadcrumbs";
import FilterTile from "../../common/FilterTile";
import JobList from "./JobList";
import Text from "../../common/Text";
import ButtonFilled from "../../common/ButtonFilled";
import { useDispatch, useSelector } from "react-redux";
import ActionTypes from "../../redux/action";
import ActiveJobsSwitch from "../../common/ActiveJobsSwitch";

interface AllJobsProps {
  isTesting?: boolean;
  selectedFiltersForRequest?: any;
}

const AllJobs: FC<AllJobsProps> = (props) => {
  const navigate = useNavigate();

  const jobFilter = useSelector((state: any) => state.jobFilter);
  const dispatch = useDispatch();

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(
    ENUMS.JOB_FILTERS.JOB_TITLE
  );
  const [searchText, setSearchText] = useState("");

  const [filterDataLoading, setFilterDataLoading] = useState(false);

  const [jobTitleData, setJobTitleData] = useState(jobFilter.jobTitleData);
  const [jobLocationData, setJobLocationData] = useState(
    jobFilter.jobLocationData
  );
  const [employmentTypeData, setEmploymentTypeData] = useState(
    jobFilter.employmentTypeData
  );
  const [jobStatusData, setJobStatusData] = useState(jobFilter.jobStatusData);
  const [hiringManagerData, setHiringManagerData] = useState(
    jobFilter.hiringManagerData
  );
  const [noOfApplicationData, setNoOfApplicationData] = useState(
    jobFilter.noOfApplicationData
  );
  const [jobPostedData, setJobPostedData] = useState(jobFilter.jobPostedData);

  const [jobTitleFilters, setJobTitleFilters] = useState(
    jobFilter.jobTitleFilters
  );
  const [jobLocationFilters, setJobLocationFilters] = useState(
    jobFilter.jobLocationFilters
  );
  const [employmentTypeFilters, setEmploymentTypeFilters] = useState(
    jobFilter.employmentTypeFilters
  );
  const [jobStatusFilters, setJobStatusFilters] = useState(
    jobFilter.jobStatusFilters
  );
  const [hiringManagerFilters, setHiringManagerFilters] = useState(
    jobFilter.hiringManagerFilters
  );
  const [noOfApplicationFilters, setNoOfApplicationFilters] = useState(
    jobFilter.noOfApplicationFilters
  );
  const [jobPostedFilters, setJobPostedFilters] = useState(
    jobFilter.jobPostedFilters
  );

  const [jobListData, setJobListData] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(false);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [pagination, setPagination] = useState(jobFilter.pagination);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const [showActiveJobs, setShowActiveJobs] = useState(
    jobFilter.showActiveJobs
  );
  const [sortModel, setSortModel] = useState<any>(jobFilter.sortModel);

  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (props.isTesting) {
      if (props.selectedFiltersForRequest) {
        setSelectedFiltersForRequest(props.selectedFiltersForRequest);
      }

      onResetAll();
      onApply();
      updateFilterKeys();
      onClearAllFilters();
      removeFilter(ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE);
      removeFilter(ENUMS.JOB_FILTERS.HIRING_MANAGER);
      removeFilter(ENUMS.JOB_FILTERS.JOB_LOCATION);
      removeFilter(ENUMS.JOB_FILTERS.JOB_POSTED_DATE);
      removeFilter(ENUMS.JOB_FILTERS.JOB_TITLE);
      removeFilter(ENUMS.JOB_FILTERS.NO_OF_APPLICATION);
      removeFilter(ENUMS.JOB_FILTERS.STATUS);
    }
  }, []);

  useEffect(() => {
    getJobList();
    updateFilterKeys();
    updatedRedux();
  }, [updateDataAgain, pagination]);

  const getJobList = () => {
    setJobListLoading(true);

    const advanceSearch = {
      jobTitle: jobTitleFilters,
      location: jobLocationFilters,
      postingDate: jobPostedFilters,
      employmentType: employmentTypeFilters,
      jobStatus: jobStatusFilters,
      hiringManager: hiringManagerFilters,
      noOfApplications: noOfApplicationFilters,
    };

    let sortField = "";
    let sortOrder = "Descending";

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = "Ascending";
    }

    const isOnlyActive =
      jobStatusFilters.length === 1 && jobStatusFilters.includes("Active");

    if (sortModel?.[0]?.field === "referenceNumber") {
      sortField = "jobId";
    } else if (sortModel?.[0]?.field === "city") {
      sortField = "location";
    } else if (sortModel?.[0]?.field === "noOfApplications") {
      sortField = "noOfApplication";
    } else if (
      sortModel?.[0]?.field === "jobTitle" ||
      sortModel?.[0]?.field === "jobType" ||
      sortModel?.[0]?.field === "jobStatus" ||
      sortModel?.[0]?.field === "hiringManagerName" ||
      sortModel?.[0]?.field === "postingDate"
    ) {
      sortField = sortModel?.[0]?.field;
    }

    const payload = {
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageIndex + 1,
      sortColumn: sortField,
      sortOrder: sortOrder,
      isSorted: true,
      advanceSearch: advanceSearch,
      showActiveJobs: isOnlyActive,
    };

    API.getJobs(payload)
      .then((response) => {
        if (response.entityList) {
          setJobListData(response.entityList);
        } else {
          setJobListData([]);
        }
        setJobListLoading(false);
        setTotalJobCount(Number(response.totalRecords));
      })
      .catch((e) => {
        setJobListLoading(false);
      });
  };

  const updatedRedux = () => {
    const updatedState = {
      jobTitleData: jobTitleData,
      jobLocationData: jobLocationData,
      employmentTypeData: employmentTypeData,
      jobStatusData: jobStatusData,
      hiringManagerData: hiringManagerData,
      noOfApplicationData: noOfApplicationData,
      jobPostedData: jobPostedData,
      jobTitleFilters: jobTitleFilters,
      jobLocationFilters: jobLocationFilters,
      employmentTypeFilters: employmentTypeFilters,
      jobStatusFilters: jobStatusFilters,
      hiringManagerFilters: hiringManagerFilters,
      noOfApplicationFilters: noOfApplicationFilters,
      jobPostedFilters: jobPostedFilters,
      pagination: pagination,
      showActiveJobs: showActiveJobs,
      sortModel: sortModel,
    };

    dispatch({ type: ActionTypes.UPDATE_JOB_STATE, payload: updatedState });
  };

  const onResetAll = () => {
    setJobTitleFilters([]);
    setJobLocationFilters([]);
    setEmploymentTypeFilters([]);
    setJobStatusFilters([]);
    setHiringManagerFilters([]);
    setNoOfApplicationFilters([]);
    setJobPostedFilters([]);
  };

  const onApply = () => {
    setUpdateDataAgain(!updateDataAgain);
    setFiltersOpen(false);
  };

  const removeFilter = (filter: string) => {
    if (filter === ENUMS.JOB_FILTERS.JOB_TITLE) {
      setJobTitleFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.JOB_LOCATION) {
      setJobLocationFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE) {
      setJobPostedFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE) {
      setEmploymentTypeFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.STATUS) {
      setJobStatusFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.HIRING_MANAGER) {
      setHiringManagerFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION) {
      setNoOfApplicationFilters([]);
    }
    setUpdateDataAgain(!updateDataAgain);
  };

  const updateFilterKeys = () => {
    let selectedFiltersForRequestPass = [];

    if (jobTitleFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_TITLE);
    }
    if (jobLocationFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_LOCATION);
    }
    if (jobPostedFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_POSTED_DATE);
    }
    if (employmentTypeFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE);
    }
    if (jobStatusFilters.length > 0) {
      const isOnlyActive =
        jobStatusFilters.length === 1 && jobStatusFilters.includes("Active");

      setShowActiveJobs(isOnlyActive);

      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.STATUS);
    } else {
      setShowActiveJobs(false);
    }

    if (hiringManagerFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.HIRING_MANAGER);
    }
    if (noOfApplicationFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.NO_OF_APPLICATION);
    }

    if (selectedFiltersForRequestPass.length > 0) {
      setSelectedFiltersForRequest([...selectedFiltersForRequestPass]);
    } else {
      setSelectedFiltersForRequest([]);
    }
  };

  const onClearAllFilters = () => {
    onResetAll();
    setUpdateDataAgain(!updateDataAgain);
  };

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  return (
    <Box
      data-testid="jobs-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { name: "Jobs" },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterPopupJobs
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={filterDataLoading}
            setDataLoading={setFilterDataLoading}
            jobTitleData={jobTitleData}
            setJobTitleData={setJobTitleData}
            jobLocationData={jobLocationData}
            setJobLocationData={setJobLocationData}
            employmentTypeData={employmentTypeData}
            setEmploymentTypeData={setEmploymentTypeData}
            jobStatusData={jobStatusData}
            setJobStatusData={setJobStatusData}
            hiringManagerData={hiringManagerData}
            setHiringManagerData={setHiringManagerData}
            noOfApplicationData={noOfApplicationData}
            setNoOfApplicationData={setNoOfApplicationData}
            jobPostedData={jobPostedData}
            setJobPostedData={setJobPostedData}
            jobTitleFilters={jobTitleFilters}
            setJobTitleFilters={setJobTitleFilters}
            jobLocationFilters={jobLocationFilters}
            setJobLocationFilters={setJobLocationFilters}
            employmentTypeFilters={employmentTypeFilters}
            setEmploymentTypeFilters={setEmploymentTypeFilters}
            jobStatusFilters={jobStatusFilters}
            setJobStatusFilters={setJobStatusFilters}
            hiringManagerFilters={hiringManagerFilters}
            setHiringManagerFilters={setHiringManagerFilters}
            noOfApplicationFilters={noOfApplicationFilters}
            setNoOfApplicationFilters={setNoOfApplicationFilters}
            jobPostedFilters={jobPostedFilters}
            setJobPostedFilters={setJobPostedFilters}
            onResetAll={onResetAll}
            onApply={onApply}
            anchorEl={anchorEl}
          />
          <ButtonBase
            data-testid="filter-toggle-btn"
            onClick={(ev) => handleClick(ev)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 0.5,
              gap: 1,
              mr: 2,
            }}
          >
            <Text fontWeight={700} fontSize={16}>
              Filters
            </Text>
            <FaAngleDown
              style={{
                marginTop: 2,
              }}
              size={18}
            />
          </ButtonBase>

          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              onClick={() => removeFilter(item)}
              title={item}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ButtonBase
              onClick={onClearAllFilters}
              data-testid="clear-all-filters-btn"
              sx={{
                p: 0.5,
              }}
            >
              <Text fontWeight={700} fontSize={16}>
                Clear All Filters
              </Text>
            </ButtonBase>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >          
          <ActiveJobsSwitch
            label="Active Jobs"
            testId="active-jobs-switch"
            switchProps={{
              checked: showActiveJobs,
              onChange: () => {
                setShowActiveJobs(!showActiveJobs);
                if (showActiveJobs) {
                  setJobStatusFilters([]);
                } else {
                  setJobStatusFilters(["Active"]);
                }
                setUpdateDataAgain(!updateDataAgain);
              },
            }}
          />
          <ButtonFilled
            title="Create Job"
            sx={{
              mr: 5,
              width: 130,
            }}
            onClick={() => {
              navigate(Paths.JOB + Paths.CREATE_JOB);
            }}
          />
        </Box>
      </Box>
      <JobList
        jobListData={jobListData}
        jobListLoading={jobListLoading}
        setJobListLoading={setJobListLoading}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalEntries={totalJobCount}
        setPagination={setPagination}
        sortModel={sortModel}
        setSortModel={setSortModel}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default AllJobs;

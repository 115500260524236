// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";
import Text from "../Text";
import Colors from "../../utils/Colors";
import LabelRadio from "../LabelRadio";

// Functional Imports

// Component Imports

interface LabelRadioSelectProps {
  label: string;
  required?: boolean;
  sx?: SxProps;
  options?: string[];
  value?: string;
  onChange?: any;
  error?: boolean;
  helperText?: string | false;

  testId?: string;
}

const LabelRadioSelect: FC<LabelRadioSelectProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        width: "23%",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={Colors.Grey9}>{props.label}</Text>
        {props.required && <Text color={Colors.Red2}>*</Text>}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 2,
          marginTop: 0.5,
        }}
      >
        {props.options?.map((item) => (
          <LabelRadio
            key={item}
            onClick={() => props.onChange(item)}
            label={item}
            checked={props.value === item}
            sx={{
              ml: -1,
            }}
            textProps={{
              fontWeight: 700,
            }}
          />
        ))}
      </Box>
      {props.error && (
        <Text
          style={{
            marginLeft: 16,
          }}
          color={Colors.Red}
          fontSize={12}
        >
          {props.helperText}
        </Text>
      )}
    </Box>
  );
};

export default LabelRadioSelect;

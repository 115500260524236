const CREATE_PASSWORD_STRINGS = {
  // To Display Use
  PASSWORD_CHANGE_SUCCESS: "Your password has been reset successfully!",
  RESET_ACCOUNT: "Let’s reset your account.",
  FINISH_ACCOUNT: "Let’s finish creating your account.",
  RESET_PASSWORD: "Reset Password",
  CREATE_PASSWORD: "Create Password",
  RESET_LINK_EXPIRED: "The reset password link has expired.",
  CREATE_LINK_EXPIRED: "The create password link has expired.",
  YOUR_PASSWORD_CREATE_SUCCESS: "Your password has been created successfully!",
  CREATION_PASSWORD_COMPLETED:
    "The creation of your password has been completed! You can now access your account by going to the login page.",
  ONE_NUMERIC_CHARACTER: "At least one numeric character",
  ONE_SPECIAL_CHARACTER: "At least one special character",
  ONE_UPPER_ONE_LOWER: "At least one uppercase & one lowercase",
  MAX_MIN: "Minimum 8 characters and maximum 16 characters",
  PASSWORD_MEET_CRITERIA: "Password must meet validation criteria",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_MUST_MATCH: "Passwords must match.",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  PLEASE_CREATE_PASSWORD_FOR: "Please create a password for ",
  DOT: ".",
  PASSWORD: "Password",
  ENTER_PASSWORD: "Enter password",
  CONFIRM_PASSWORD: "Confirm Password",

  // To Functional Use
  RESET_PASSWORD_KEY: "reset_password",
};

const FORGOT_PASSWORD_STRINGS = {
  ENTER_VALID_EMAIL: "Please enter valid Email ID",
  EMAIL_IS_REQUIRED: "Email ID is required",
  RESEND_EMAIL_SUCCESS: "Resend email successful!",
  EMAIL_NOT_EXIST: "Email does not exist.",
};

const LOGIN_PAGE_STRINGS = {
  EMAIL_IS_REQUIRED: "Email is required",
  ENTER_VALID_EMAIL: "Please enter valid email ID",
  PASSWORD_IS_REQUIRED: "Password is required",
};

const REJECT_POPUP_STRINGS = {
  REJECTION_REASON: "Rejection reason",
  NOTES: "Notes",
  REJECT: "Reject",
};

const HIRE_POPUP_STRINGS = {
  HIRE: "Hire",
  APPLICANT_NAME: "Applicant Name",
  PROJECT_START_DATE: "Project Start Date",
  HIRING_MANAGER: "Hiring Manager",
  LOCATION: "Location",
  DO_YOU_WISH_TO_CLOSE_THIS_JOB: "Do you wish to close this job?",
  HOW_MANY_POSITIONS_ARE_REMAINING: "How many positions are remaining?",
  COMMENTS: "Comments",
  YES: "Yes",
  NO: "No",
};

const SHARE_POPUP_STRINGS = {
  SHARE_APPLICATION: "Share application",
  SHARE: "Share",
  REPLY_TO_EMAIL_ID: "Reply to Email Id",
  REPLIES_WILL_BE_SENT_TO_THIS_EMAIL_ADDRESS:
    "Replies will be sent to this email address.",
};

const CREATE_JOB_STRING = {
  CREATE_JOB_HEADING: "Create Job",
  JOB_ID: "Job ID",
  HIRING_MANAGER: "Hiring manager",
  JOB_LOCATION: "Location of the job",
  JOB_POSTED: "Job posted date",
  JOB_TITLE: "Job Title",
  MODE_OF_WORK: "Mode of Work",
  SKILL_SET: "Skill Set",
  SKILL_SET_PLACEHOLDER: "Enter relevant skillset",
  NUMBER_OF_OPENING: "Number of Openings",
  EMPLOYMENT_TYPE: "Employment Type",
  INTERVIEW_MODE: "Interview Mode",
  BILL_RATE: "Bill Rate",
  COUNTRY: "Country",
  STATE: "State",
  CITY: "City",
  ZIP: "Zip",
  ANNUALSALARY: "Annual Salary",
  ENHANCE_JD: "Enhance JD",
  GENRATE_JD: "Generate JD",
  CANCEL: "Cancel",
  DELETE: "Delete",
  NEXT: "Next",
  BACK: "Back",
  ENTER: "Enter ",
  PREVIEW: "Preview",
  PUBLISH: "Publish",
  PUBLISH_JOB: "Publish Job",
  JOD_DESCRIPTION: "Job Description",
  CANCEL_JOB_LABEL: "Do you want to cancel job creation?",
  CANCEL_JOB_NOTE: 'Data will not be saved if you click "Yes."',
  TITLE_LENGTH_ERROR_MSG: "Job title should not be more then 100 characters",
  TITLE_REQUIRED_ERROR_MSG: "This field is required",
  PJ_HEADING_TEXT: "To create a job, please provide more details.",
  JD_HEADING_TEXT:
    'Add a Job title and click "Generate JD" if you need a job description.',
  JD_OPTIONAL_FIELD_TEXT:
    "*For a more precise job description, fill in the optional fields.",
  ENHANCE_JD_NOTE:
    "Note: Enhance JD for a refined and improved job description.",
  FILL_REQ_FIELD_TEXT: "This field is required",
  JD_REQ_FIELD_TEXT: "Please fill job description to proceed.",
  REQUIRED_DETAIL: "Required Details",
  CERTIFICATE: "Certifications and licenses",
  TOOLS: "Tools",
  TECHNOLOGY: "Technology",
};

const EMPTY_LIST_STRINGS = {
  NO_RESULTS_FOUND: "No Results Found",
  LOREM_IPSUM:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id justo mauris. In in sollicitudin dolor. Sed molestie mauris placerat sapien porta convallis.",
  RESET_FILTERS: "Reset Filters",
};

const APPLICATIONS_STRINGS = {
  FILTERS: "Filters",
  CLEAR_ALL_FILTERS: "Clear all filters",
  ACTIVE_JOBS: "Active Jobs",
};

const PROPOSE_TIME_SLOTS = {
  DATE_FORMAT: "YYYY-MM-DD",
  TIME_FORMAT: "HH:mm",
  DEFAULT_FROM_TIME: "14:00",
  DEFAULT_TO_TIME: "15:00",
  TOAST_DURATION: 3000,
  MAX_SLOTS: 3,
  CALENDAR_HEADER_DATE_FORMAT: "MMMM YYYY",
  INPUT_DATE_FORMAT: "MM/DD/YYYY",
  SLOT_CONTAINER_COLOR: "#F8F8F8",
  SLOT_BORDER_COLOR: "#D1D1D1",
  SLOT_STRIPE_COLOR: "#d0d0d0",
  EMPTY_SLOT_STRIPE_COLOR: "#fff",
  SELECTED_SLOT_COLOR: "#4A57A3",
  TODAY_SLOT_COLOR: "#D7DBEF",
  EMPTY_SLOT_COLOR: "#f5f5f5",
  ERROR_COLOR: "#F81919",
  AUTH_ERROR: "Authentication token is missing. Please log in again.",
  UNAUTHORIZED_ERROR: "Unauthorized. Please log in again.",
  PLACEHOLDER_NAME: "Andrew",
  PLACEHOLDER_EMAIL: "kiran.ash@xxxx.com",
  CONFIRMATION_TEXT:
    "Do you really wish to cancel the proposed interview time?",
  INFO_TEXT: "This info will not be saved if you cancel.",
};

const COMMONSTR = {
  SAVE: "SAVE",
  DETAIL: "Details",
  STATUS: "Status",
  EDIT: "Edit",
};
const STRINGS = {
  CREATE_PASSWORD: CREATE_PASSWORD_STRINGS,
  FORGOT_PASSWORD: FORGOT_PASSWORD_STRINGS,
  LOGIN_PAGE: LOGIN_PAGE_STRINGS,
  REJECT_POPUP: REJECT_POPUP_STRINGS,
  HIRE_POPUP: HIRE_POPUP_STRINGS,
  SHARE_POPUP: SHARE_POPUP_STRINGS,
  PROPOSE_TIME_SLOTS: PROPOSE_TIME_SLOTS,
  CREATE_JOB: CREATE_JOB_STRING,
  EMPTY_LIST: EMPTY_LIST_STRINGS,
  APPLICATIONS: APPLICATIONS_STRINGS,
  COMMONSTR: COMMONSTR,
};

export default STRINGS;

// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface FilterNameProps {
  isActive?: boolean;
  title?: string;
  sx?: SxProps;
  onClick?: any;
  testId?: string;
}

const FilterName: FC<FilterNameProps> = (props) => {
  return (
    <ButtonBase
      data-testid={props.testId}
      onClick={props.onClick}
      sx={{
        height: 40,
        width: "100%",
        backgroundColor: props.isActive ? Colors.White : null,
        display: "flex",
        pl: 2.5,
        alignItems: "center",
        justifyContent: "flex-start",
        ...props.sx,
      }}
    >
      <Text>{props.title}</Text>
    </ButtonBase>
  );
};

export default FilterName;

const LogoTagline = require("../assets/images/logo-tagline.png");
const BackgroundLogin = require("../assets/images/background-login.png");
const VelocityLogo = require("../assets/images/velocity-logo.png");
const MailIcon = require("../assets/icons/mail-icon.png");
const BellIcon = require("../assets/icons/bell-icon.png");
const AnimatedLoader = require("../assets/icons/animated-loader.gif");
const EmptyApplicationsList = require("../assets/images/empty-applications-list.png");

const Images = {
  LogoTagline,
  BackgroundLogin,
  MailIcon,
  VelocityLogo,
  BellIcon,
  EmptyApplicationsList,
  AnimatedLoader
};

export default Images;

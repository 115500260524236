// React Imports
import { FC, MouseEventHandler } from "react";

// UI Imports
import { Button, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";
import { Oval } from "react-loader-spinner";

interface BlueButtonProps {
  sx?: SxProps;
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
}

const BlueButton: FC<BlueButtonProps> = (props) => {
  return (
    <Button
      data-testid={props.testId}
      disabled={props.disabled || props.loading}
      variant="contained"
      onClick={props.onClick}
      fullWidth={false}
      sx={{
        width: 120,
        height: 40,
        boxShadow: "none",
        textTransform: "none",
        backgroundColor: Colors.Blue7,
        ":hover": {
          backgroundColor: Colors.Blue7,
        },
        ":disabled": {
          backgroundColor: Colors.Blue7 + "AA",
        },
        ...props.sx,
      }}
    >
      {!props.loading && (
        <Text fontSize={18} fontWeight={700} color={Colors.White}>
          {props.title}
        </Text>
      )}
      {props.loading && (
        <Oval color={Colors.White} secondaryColor={Colors.Black1} height={20} />
      )}
    </Button>
  );
};

export default BlueButton;

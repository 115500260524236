import { Box } from "@mui/material";
import { FC } from "react";
import { Bounce, toast } from "react-toastify";
import Colors from "./Colors";
import { FaCheckCircle } from "react-icons/fa";
import Text from "../common/Text";
import "./Toasts.css";
import { MdError } from "react-icons/md";

enum TOAST_OPTIONS {
  SUCCESS,
  ERROR,
  WARNING,
}

const toastConfig: any = {
  position: "bottom-center",
  hideProgressBar: true,
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

interface ToastComponentProps {
  message?: string;
  type?: number;
  testId?: string;
}

export const ToastComponent: FC<ToastComponentProps> = (props) => {
  let border;
  let backgroundColor;
  let icon;
  let textColor;

  if (props.type === TOAST_OPTIONS.SUCCESS) {
    border = "1px solid " + Colors.Green3;
    backgroundColor = Colors.LightGreen2;
    icon = <FaCheckCircle color={Colors.Green3} />;
    textColor = Colors.Green3;
  } else if (props.type === TOAST_OPTIONS.ERROR) {
    border = "1px solid " + Colors.Red;
    backgroundColor = Colors.LightRed;
    icon = <MdError color={Colors.Red} size={22} />;
    textColor = Colors.Red;
  }

  return (
    <Box
      data-testid={props.testId}
      sx={{
        height: 40,
        border: border,
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        gap: 1.5,
        px: 2,
      }}
    >
      {icon}
      <Text fontSize={18} color={textColor}>
        {props.message}
      </Text>
    </Box>
  );
};

const success = (message: string) => {
  toast(<ToastComponent message={message} type={TOAST_OPTIONS.SUCCESS} />, {
    ...toastConfig,
    className: "toast-class-success",

    // toastClassName: "toast-class-success",
  });
};

const error = (message: string) => {
  toast(<ToastComponent message={message} type={TOAST_OPTIONS.ERROR} />, {
    ...toastConfig,
    className: "toast-class-error",
  });
};

const Toasts = {
  success,
  error,
};

export default Toasts;

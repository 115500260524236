// React Imports
import { FC } from "react";

// Router Imports

// Page Imports
import Functions from "../utils/Functions";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import NoAuthRoutes from "./NoAuthRoutes";

interface RoutesProps {}

const Routes: FC<RoutesProps> = (props) => {
  const JWTToken = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.JWT_TOKEN
  );

  if (JWTToken !== "" && JWTToken !== undefined && JWTToken !== null) {
    return <AuthenticatedRoutes />;
  } else {
    return <NoAuthRoutes />;
  }
};

export default Routes;

import dayjs from 'dayjs';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import STRINGS from '../../utils/Strings';

const CustomCalendarHeader = (props: any) => {
  const { currentMonth, onMonthChange } = props;

  const handlePrevMonth = () => {
    onMonthChange(dayjs(currentMonth).subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    onMonthChange(dayjs(currentMonth).add(1, 'month'));
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
      <IconButton onClick={handlePrevMonth} data-testid="prev-month-button">
        <ArrowLeft />
      </IconButton>
      <Typography variant="h6" sx={{ mx: 2 }}>
        {dayjs(currentMonth).format(STRINGS.PROPOSE_TIME_SLOTS.CALENDAR_HEADER_DATE_FORMAT)}
      </Typography>
      <IconButton onClick={handleNextMonth} data-testid="next-month-button">
        <ArrowRight />
      </IconButton>
    </Box>
  );
};

export default CustomCalendarHeader;

import moment from "moment";
import { Bounce, toast } from "react-toastify";

const formatUnixDate = (
  unixDate: number | string,
  format: string = "MM/DD/YYYY"
) => {
  return moment.unix(Number(unixDate)).format(format);
};

const formatDateObject = (date: any, format: string = "DD-MM-YYYY") => {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return "";
  }
};

const inputDateValidation = (dateValue: string) => {
  const isValid = moment(dateValue).isValid();

  if (isValid) {
    const year = moment(dateValue).year();

    if (year < 1900 || year > 2100) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const DateFunctions = {
  formatUnixDate,
  formatDateObject,
  inputDateValidation,
};

const warnToast = (message: string) => {
  toast.warn(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const errorToast = (message: string) => {
  toast.error(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const successToast = (message: string) => {
  toast.success(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const Toast = {
  warn: warnToast,
  error: errorToast,
  success: successToast,
};

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return email.toLowerCase().match(emailRegex);
};

const Validation = {
  email: validateEmail,
};

const storeString = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const getString = (key: string) => {
  return localStorage.getItem(key);
};

const LOCAL_STORAGE_KEYS = {
  JWT_TOKEN: "JWT_TOKEN",
  USER_ID: "USER_ID",
  USER_NAME: 'USER_NAME',
  Remaining_Attemts: "Remaining_Attemts",
};

const LocalStorage = {
  storeString,
  getString,
  KEYS: LOCAL_STORAGE_KEYS,
};

const getCountry = (countryCode: string | number) => {
  return String(countryCode);
};

const phoneNumberResolve = (number: string) => {
  const arr = number.split(" ");
  let phoneNumberData = {
    countryCode: 0,
    phoneNumber: "",
  };
  arr.forEach((item: string, index: number) => {
    if (index === 0) {
      phoneNumberData.countryCode = Number(item);
    } else {
      phoneNumberData.phoneNumber = phoneNumberData.phoneNumber + item;
    }
  });
  return phoneNumberData;
};

const geocodeResponseToCityState = (geocodeJSON: any) => {
  let locality: any = [];
  if (geocodeJSON.results.length) {
    let addressarr = geocodeJSON.results[0].address_components;
    addressarr.forEach((item: any) => {
      if (item.types.includes("locality")) {
        locality.city = item.long_name;
      } else if (item.types.includes("administrative_area_level_1")) {
        locality.stateName = item.long_name;
        locality.stateCode = item.short_name;
      } else if (item.types.includes("sublocality_level_1")) {
        locality.city = item.long_name;
      } else if (item.types.includes("country")) {
        locality.countryName = item.long_name;
        locality.countryCode = item.short_name;
      }
    });
  } else {
    console.log("error: no address components found");
  }
  return locality;
};

const getSkillandLicenses = (manualdata: any) => {
  let data: any = {};
  let skillarr: any = [];
  let cl_arr: any = [];
  if (manualdata.skills.length) {
    manualdata.skills?.forEach((item: any) => {
      skillarr.push(item.skill);
    });
    let skills = skillarr.join();
    data.skillsarr = skillarr;
    data.skills = skills;
  }
  if (manualdata.certificationsAndLicenses.length) {
    manualdata.certificationsAndLicenses?.forEach((item: any) => {
      cl_arr.push(item.certificationsAndLicense);
    });

    data.certificationsAndLicense = cl_arr;
  }
  return data;
};

const getJobType = (jobType: string) => {
  let jobTypePass = "";

  if (jobType === "R") {
    jobTypePass = "Right To Hire";
  } else if (jobType === "F") {
    jobTypePass = "Full Time";
  } else if (jobType === "C") {
    jobTypePass = "Contract";
  } else {
    jobTypePass = jobType;
  }

  return jobTypePass;
};

const getInterviewType = (interviewType: number) => {
  let InterviewTypePass = "";

  if (interviewType === 2) {
    InterviewTypePass = "Telephone";
  } else if (interviewType === 4) {
    InterviewTypePass = "Personal";
  } else if (interviewType === 8) {
    InterviewTypePass = "Webcam";
  } else if (interviewType === 16) {
    InterviewTypePass = "No Interview Required";
  }

  return InterviewTypePass;
};

const getModeofWork = (modeType: string) => {
  let modeTypePass = "";

  if (modeType === "remote") {
    modeTypePass = "Remote";
  } else if (modeType === "onsite") {
    modeTypePass = "Onsite";
  } else if (modeType === "hybrid") {
    modeTypePass = "Hybrid";
  }
  return modeTypePass;
};

const getJobDescriptionValue = (messageListPass: any) => {
  let ejd = "";
  messageListPass.map((listitem: any) => {
    if (listitem.jobTitle.length > 0) {
      ejd += "<p><b>Job title:</b><span>  " + listitem.jobTitle + "</span></p>";
    }
    if (listitem.industry.name.length > 0) {
      ejd +=
        "<p><b>Industry:</b><span>  " + listitem.industry.name + "</span></p>";
    }
    if (listitem.domain.name.length > 0) {
      ejd += "<p><b>Domain:</b><span>  " + listitem.domain.name + "</span></p>";
    }

    if (listitem.location.length > 0) {
      ejd += "<p><b>location:</b><span>  " + listitem.location + "</span></p>";
    }

    if (listitem.aboutClient.length > 0) {
      ejd += "<p><b>Client:</b></p>";
      ejd += "<p>" + listitem.aboutClient + "</p>";
    }
    if (listitem.description.length > 0) {
      ejd += "<p><b>Description:</b></p>";
      ejd += "<p>" + listitem.description + "</p>";
    }
    if (listitem.rolesAndResponsibilities.length > 0) {
      ejd += "<p><b>RolesAndResponsibilities:</b></p>";
      ejd += "<ul>";
      listitem.rolesAndResponsibilities.map((item: any) => {
        ejd += "<li>" + item + "</li>";
      });
      ejd += "</ul>";
    }
    if (listitem.technicalSkills.length > 0) {
      ejd += "<p><b>TechnicalSkills:</b></p>";
      ejd += "<ul>";
      listitem.technicalSkills.map((item: any) => {
        ejd +=
          "<p><b> " +
          item.requirement +
          ":</b><span>  " +
          item.skill +
          "</span></p>";
      });
      ejd += "</ul>";
    }

    if (listitem.certifications.length > 0) {
      ejd += "<ul>";
      listitem.certifications.map((item: any) => {
        ejd += "<li>" + item + "</li>";
      });
      ejd += "</ul>";
    }
  });
  return ejd;
};

const returnStringNotNull = (str: string | null | undefined | 0) => {
  if (str !== null && str !== undefined && str !== 0) {
    return str;
  } else return "";
};

const returnStringWithDollarNotNull = (str: string | null | undefined | 0) => {
  if (str !== null && str !== undefined && str !== 0) {
    return "$" + str;
  } else return "";
};

const modifyCurrentJob = (values: any, jobData: any) => {
  let ModifyJobOldNewValue =
    '<table style="width: 100%; font-size: 12px; font-family: arial, sans-serif;" border="0" width="100%" cellspacing="0" cellpadding="0"><thead><tr><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">Field</th><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">Old Value</th><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">New Value</th></tr></thead><tbody>';
  let jobFlag = false;

  Object.keys(jobData).forEach((item) => {
    let apidat = jobData[item];
    let editdata = values[item];
    let itemlable = "";
    switch (item) {
      case "jobTitle":
        itemlable = "Job Title:";
        break;
      case "numberOfOpenings":
        itemlable = "No. of Openings:";
        editdata = returnStringNotNull(editdata);
        apidat = returnStringNotNull(apidat);
        break;
      case "city":
        apidat = returnStringNotNull(apidat);
        itemlable = "City:";
        editdata = returnStringNotNull(editdata);
        break;
      case "zipCode":
        editdata = returnStringNotNull(editdata);
        itemlable = "Zip Code:";
        apidat = returnStringNotNull(apidat);
        break;
      case "jobDescription":
        editdata = returnStringNotNull(editdata);
        itemlable = "Job Description:";
        apidat = returnStringNotNull(apidat);
        break;
      case "modeofwork":
        apidat = returnStringNotNull(apidat);
        editdata = returnStringNotNull(editdata);
        itemlable = "Mode of Work:";
        break;
      case "billRate":
        itemlable = "Bill Rate:";
        editdata = returnStringWithDollarNotNull(editdata);
        apidat = returnStringWithDollarNotNull(apidat);
        break;
      case "skillSet":
        itemlable = "Skill Set:";
        editdata = returnStringNotNull(editdata);
        apidat = returnStringNotNull(apidat);
        break;
      case "jobStatus":
        apidat = returnStringNotNull(apidat);
        itemlable = "Job Status:";
        editdata = returnStringNotNull(editdata);
        break;
      case "salaryRange":
        apidat = returnStringWithDollarNotNull(apidat);
        editdata = returnStringWithDollarNotNull(editdata);
        itemlable = "Annual Salary:";
        break;
      case "stateName":
        itemlable = "State:";
        editdata = returnStringNotNull(editdata);
        apidat = returnStringNotNull(apidat);
        break;
      case "countryName":
        apidat = returnStringNotNull(apidat);
        itemlable = "Country:";
        editdata = returnStringNotNull(editdata);
        break;
      case "interviewtype":
        itemlable = "Interview Type:";
        apidat = Functions.ValueRetrieve.getInterviewType(apidat);
        editdata = Functions.ValueRetrieve.getInterviewType(editdata);
        break;
      case "jobType":
        itemlable = "Job Type:";
        apidat = Functions.ValueRetrieve.getJobType(apidat);
        editdata = Functions.ValueRetrieve.getJobType(editdata);
        break;
      default:
        itemlable = item;
        break;
    }
    if (
      item === "skillarr" ||
      item === "countryCode" ||
      item === "stateCode" ||
      item === "ModifyJobOldNewValue"
    ) {
      ModifyJobOldNewValue += "";
    } else if (item === "jobDescription") {
      apidat = apidat.replace(/(<([^>]+)>)/gi, "");
      editdata = editdata.replace(/(<([^>]+)>)/gi, "");
      if (apidat != editdata) {
        jobFlag = true;
        ModifyJobOldNewValue += "<tr>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; font-weight: bold; border-top: 0px; padding: 5px;'>" +
          itemlable +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          apidat +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          editdata +
          "</td>";
        ModifyJobOldNewValue += "</tr>";
      }
    } else {
      if (apidat != editdata) {
        jobFlag = true;
        ModifyJobOldNewValue += "<tr>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; font-weight: bold; border-top: 0px; padding: 5px;'>" +
          itemlable +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          apidat +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          editdata +
          "</td>";
        ModifyJobOldNewValue += "</tr>";
      }
    }
  });
  if (!jobFlag) {
    ModifyJobOldNewValue +=
      '<tr><td colspan="3" style="text-align: center;" >No modifications has been made.</td></tr>';
  }
  ModifyJobOldNewValue += "</tbody></table>";

  return ModifyJobOldNewValue;
};

const getYearTitle = (year: string) => {
  const numVal = Number(year);

  if (isNaN(numVal)) {
    return year;
  } else {
    if (numVal > 1) {
      return year + " Years";
    } else {
      return year + " Year";
    }
  }
};

const getJobListRowClassName = (postingDate: any) => {
  const postingDatePass = moment(postingDate);

  const currentDate = moment(new Date());

  let difference = Number(currentDate.diff(postingDatePass)) / 1000;

  if (difference < 120) {
    return "new-row-style";
  } else return "";
};

const ValueRetrieve = {
  country: getCountry,
  phoneNumberResolve,
  getJobType,
  getModeofWork,
  getInterviewType,
  geocodeResponseToCityState,
  getSkillandLicenses,
  getJobDescriptionValue,
  modifyCurrentJob,
  getYearTitle,
  getJobListRowClassName,
};

const removeItemOnce = (arr: any, value: any) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

const ArrayFunctions = {
  removeItemOnce,
};

const booleanAnd = (
  firstBool: boolean | undefined,
  secondBool: boolean | undefined
) => {
  return firstBool && secondBool;
};

const booleanAndString = (
  bool: boolean | undefined,
  str: string | undefined
) => {
  return bool && str;
};

const Sonar = {
  booleanAnd,
  booleanAndString,
  returnStringNotNull,
  returnStringWithDollarNotNull,
};

const fileDownload = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};

const Download = {
  fileDownload,
};

const Functions = {
  DateFunctions,
  Toast,
  Validation,
  LocalStorage,
  ValueRetrieve,
  ArrayFunctions,
  Sonar,
  Download,
};

export default Functions;

// React Imports
import { FC } from 'react';

import { Box, Modal, IconButton } from '@mui/material'
import { IoClose } from "react-icons/io5";
import Paths from "../../routes/Paths";

import Text from "../../common/Text";
import ButtonFilled from '../../common/ButtonFilled';
import ButtonOutlined from '../../common/ButtonOutlined';
import Colors from '../../utils/Colors';
import STRINGS from '../../utils/Strings';
import { useNavigate } from "react-router-dom";


interface CancelJobProps {
    cancelopen: boolean;
    setCancelopen?: any;
}

const CancelJob: FC<CancelJobProps> = (props) => {

    const handleCancel = () => {
        props.setCancelopen(false);
    };

    const navigate = useNavigate();

    return (<Modal open={props.cancelopen} onClose={handleCancel}>
        <Box
            sx={{
                position: "absolute",
                width: "400px",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
                backgroundColor: Colors.White,
                borderRadius: "8px",
            }}
        >
            <Box sx={{
                    height: 40,
                    width: "100%",
                }}>
                <IconButton
                    data-testid="close-cancel"
                    sx={{
                        height: 60,
                        float: 'right',

                    }}
                    onClick={() => props.setCancelopen(false)}>
                    <IoClose />
                </IconButton>
            </Box>
            <Box sx={{
                px: 2,
                textAlign: "center",
            }}>
                <Text fontSize={20} fontWeight={700} style={{ marginTop: "10px" }}>
                    {STRINGS.CREATE_JOB.CANCEL_JOB_LABEL}
                </Text>
                <Text fontSize={14} fontWeight={500} style={{ marginTop: "10px" }}>
                    {STRINGS.CREATE_JOB.CANCEL_JOB_NOTE}
                </Text>

                <Box
                    sx={{
                        width: '55%',
                        display: "flex",
                        justifyContent: "space-around",
                        margin: "25px auto",
                    }}
                >
                    <ButtonOutlined title={STRINGS.HIRE_POPUP.YES} sx={{ px: 2, height: 40 }} onClick={() => { navigate(Paths.JOBS) }}/>
                    <ButtonFilled title={STRINGS.HIRE_POPUP.NO} sx={{ px: 2, height: 40 }}  onClick={() => props.setCancelopen(false)} />

                </Box>
            </Box>

        </Box>
    </Modal>
    );
};

export default CancelJob;
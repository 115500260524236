// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton } from "@mui/material";
import { FaAngleLeft } from "react-icons/fa6";

// Functional Imports
import { object, string } from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import Images from "../../utils/Images";
import STRINGS from "../../utils/Strings";

// Component Imports
import LogoTaglineChild from "../../common/LogoTaglineChild";
import NumberCircle from "./NumberCircle";
import BlueButton from "../../common/BlueButton";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";
import CONSTANTS from "../../utils/Constants";

const initialEmailSeconds = 30;

interface ForgotPasswordProps {
  isTesting?: boolean;
  isSubmitted?: boolean;
  resendEmail?: boolean;
  resendEmailEnable?: boolean;
  resendEmailSeconds?: number;
}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [resendEmailEnable, setResendEmailEnable] = useState(false);
  const [resendEmailSeconds, setResendEmailSeconds] =
    useState(initialEmailSeconds);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: object({
      email: string()
        .email(STRINGS.FORGOT_PASSWORD.ENTER_VALID_EMAIL)
        .required("Email ID is required"),
    }),
    onSubmit: (values) => {
      setDisableSubmit(true);
      API.emailValidation(values.email)
        .then((response) => {
          setDisableSubmit(false);

          if (isSubmitted) {
            Toasts.success(
              "The email has been sent. Check your inbox or spam folder."
            );
            setResendEmailEnable(false);
            setResendEmailSeconds(initialEmailSeconds);
          }
          if (response.entity?.isEmailValidated) {
            setIsSubmitted(true);
          }
          if (response.isError) {
            if (
              response?.message?.appStatusDescription ===
              STRINGS.FORGOT_PASSWORD.EMAIL_NOT_EXIST
            ) {
              Toasts.error(STRINGS.FORGOT_PASSWORD.ENTER_VALID_EMAIL);
            } else {
              Toasts.error(response?.message?.appStatusDescription);
            }
          }
        })
        .catch((e: any) => {
          setDisableSubmit(false);

          if (e.trim() === STRINGS.FORGOT_PASSWORD.EMAIL_NOT_EXIST) {
            Toasts.error(STRINGS.FORGOT_PASSWORD.ENTER_VALID_EMAIL);
          } else {
            Toasts.error(e);
          }
        });
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      updateTime();
      formik
        .setValues({
          email: "email@vln.com",
        })
        .then(() => {
          formik.handleSubmit();
        });

      if (props.isSubmitted) {
        setIsSubmitted(props.isSubmitted);
      }

      if (props.resendEmail) {
        setResendEmail(props.resendEmail);
      }

      if (props.resendEmailEnable) {
        setResendEmailEnable(props.resendEmailEnable);
      }

      if (props.resendEmailSeconds) {
        setResendEmailSeconds(props.resendEmailSeconds);
      }
    }
  }, []);

  useEffect(() => {
    if (!props.isTesting) {
      if (CONSTANTS.REGEX.EMAIL.test(state.email)) {
        formik.setFieldValue("email", state.email);
      }
    }
  }, []);

  useEffect(() => {
    let interval: any;
    if (isSubmitted) {
      interval = setInterval(() => {
        updateTime();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isSubmitted, resendEmail]);

  const updateTime = () => {
    setResendEmailSeconds((prevValue) => {
      if (0 < prevValue && prevValue <= 30) {
        return prevValue - 1;
      } else {
        setResendEmailEnable(true);
        return 0;
      }
    });
  };

  return (
    <LogoTaglineChild testId="forgot-password-page">
      <Box
        sx={{
          p: 4,
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton
            data-testid="back-to-login-btn"
            onClick={() => navigate(Paths.LOGIN)}
            sx={{
              backgroundColor: Colors.LightBlue1,
              height: 32,
              width: 32,
            }}
          >
            <FaAngleLeft size={20} color={Colors.Blue7} />
          </IconButton>
          <Text fontSize={18} fontWeight={700} color={Colors.Blue7}>
            Back to login page
          </Text>
        </Box>

        {!isSubmitted && (
          <>
            <Text fontWeight={700} fontSize={24}>
              Forgot Password?
            </Text>
            <Text fontSize={16}>
              Enter your Email ID associated with the account.
            </Text>

            <LabelInput
              label="Email ID"
              required={true}
              textFieldProps={{
                placeholder: "Enter Email ID",
                sx: {
                  width: 300,
                  mt: 1,
                },
                id: "email",
                name: "email",
                value: formik.values.email,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                error: formik.touched.email && Boolean(formik.errors.email),
                helperText: formik.touched.email && formik.errors.email,
              }}
            />

            <BlueButton
              disabled={disableSubmit}
              testId="submit-btn"
              onClick={() => formik.handleSubmit()}
              title="Submit"
            />
          </>
        )}

        {isSubmitted && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                component={"img"}
                src={Images.MailIcon}
                sx={{
                  height: 60,
                  width: 150,
                }}
              />
            </Box>

            <Text fontSize={16}>
              Check your inbox for an email from Velocity. A password reset link
              has been sent. If you don't see it in your inbox, consider the
              following steps.
            </Text>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: -1,
              }}
            >
              <NumberCircle title="1" />
              <Text fontSize={16} fontWeight={500}>
                Check your spam folder.
              </Text>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: -1,
              }}
            >
              <NumberCircle title="2" />
              <Text fontSize={16} fontWeight={500}>
                Haven’t received the email yet?
                <span
                  data-testid={"resend-email-btn"}
                  onClick={() => {
                    if (resendEmailEnable) {
                      formik.handleSubmit();
                    }
                  }}
                  style={{
                    cursor: resendEmailEnable ? "pointer" : undefined,
                    color: resendEmailEnable ? Colors.Blue7 : Colors.Grey1,
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Resend Email
                </span>
                {!resendEmailEnable && !resendEmail && " " + resendEmailSeconds}
              </Text>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: -1,
              }}
            >
              <NumberCircle title="3" />
              <span
                data-testid="contact-us-btn"
                onClick={() =>
                  (window.location.href = "mailto:contact@velocitymsp.ai")
                }
                style={{
                  cursor: "pointer",
                  color: Colors.Blue7,
                  fontWeight: 700,
                }}
              >
                {" "}
                Contact Us.
              </span>
            </Box>
          </>
        )}
      </Box>
    </LogoTaglineChild>
  );
};

export default ForgotPassword;

// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";
import Colors from "../../utils/Colors";
import Text from "../../common/Text";

// Functional Imports

// Component Imports

interface NumberCircleProps {
  title?: string;
  sx?: SxProps;
}

const NumberCircle: FC<NumberCircleProps> = (props) => {
  return (
    <Box
      sx={{
        height: 32,
        width: 32,
        backgroundColor: Colors.Grey1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 16,
        ...props.sx,
      }}
    >
      <Text fontSize={16} fontWeight={700}>
        {props.title}
      </Text>
    </Box>
  );
};

export default NumberCircle;

// React Imports
import { FC } from "react";

// UI Imports
import { Box, Stepper, Step, StepLabel, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports

interface StaperProps {
  label: any[];
  sx?: SxProps;
  activeStap: any;
  testId?: string;
}

const Staper: FC<StaperProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        ...props.sx,
      }}
    >
      <Stepper activeStep={props.activeStap} alternativeLabel>
        {props.label.map((item: any, index: number) => {
          return (
            <Step
              key={item}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: Colors.Blue7, // circle color (COMPLETED)
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: Colors.Blue7, // circle color (ACTIVE)
                },
              }}
            >
              <StepLabel>{item}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default Staper;

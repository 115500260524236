// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";
import Colors from "../../utils/Colors";

// Functional Imports
import moment from "moment";

// Component Imports
import Text from "../../common/Text";
import { Link } from "react-router-dom";
import Paths from "../../routes/Paths";

interface AboutFooterProps {
  sx?: SxProps;
}

const AboutFooter: FC<AboutFooterProps> = (props) => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        ...props.sx,
      }}
    >
      <Text color={Colors.White}>
        Copyright Velocity MSP Inc. {moment().format("YYYY")}, all rights
        reserved.
      </Text>
      <Box
        sx={{
          height: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
          }}
          to={Paths.CONTACT_US + "/n"}
        >
          <Text color={Colors.White}>Contact us</Text>
        </Link>
        <Box
          sx={{
            height: "100%",
            width: 2,
            backgroundColor: Colors.Grey5,
            margin: "0px 16px 0px 16px",
          }}
        />
        <a
          style={{
            textDecoration: "none",
          }}
          href="https://www.velocitymsp.ai/privacy-policy"
        >
          <Text color={Colors.White}>Privacy policy</Text>
        </a>
        <Box
          sx={{
            height: "100%",
            width: 2,
            backgroundColor: Colors.Grey5,
            margin: "0px 16px 0px 16px",
          }}
        />
        <a
          style={{
            textDecoration: "none",
          }}
          href="https://www.velocitymsp.ai/terms-of-use"
        >
          <Text color={Colors.White}>Terms of use</Text>
        </a>
      </Box>
    </Box>
  );
};

export default AboutFooter;

// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Oval } from "react-loader-spinner";

// Functional Imports
import { useNavigate, useSearchParams } from "react-router-dom";
import { object, ref, string } from "yup";
import { useFormik } from "formik";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import STRINGS from "../../utils/Strings";
import CONSTANTS from "../../utils/Constants";

// Component Imports
import PasswordValidationTile from "./PasswordValidationTile";
import LogoTaglineChild from "../../common/LogoTaglineChild";
import BlueButton from "../../common/BlueButton";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";

enum CREATE_PASSWORD {
  EXPIRED,
  VALID,
  PASSWORD_CREATED,
}

interface CreatePasswordProps {
  isTesting?: boolean;
  dataLoading?: boolean;
  beforeSubmit?: boolean;
  isResetOrCreatePasswordTest?: boolean;
  passwordLinkExpired?: boolean;
}

const CreatePassword: FC<CreatePasswordProps> = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const Key = searchParams.get("q");
  const UTMSource = searchParams.get("utm_source");
  const IsReminder = searchParams.get("isReminder");

  const isResetOrCreatePassword =
    UTMSource === STRINGS.CREATE_PASSWORD.RESET_PASSWORD_KEY;

  const [email, setEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [beforeSubmit, setBeforeSubmit] = useState(true);
  const [passwordLinkExpired, setPasswordLinkExpired] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object({
      password: string()
        .matches(
          CONSTANTS.REGEX.PASSWORD2,
          STRINGS.CREATE_PASSWORD.PASSWORD_MEET_CRITERIA
        )
        .required(STRINGS.CREATE_PASSWORD.PASSWORD_REQUIRED),
      confirmPassword: string()
        .oneOf([ref("password")], STRINGS.CREATE_PASSWORD.PASSWORD_MUST_MATCH)
        .required(STRINGS.CREATE_PASSWORD.CONFIRM_PASSWORD_REQUIRED),
    }),
    onSubmit: (values) => {
      API.resetPassword(Key, values.password, values.confirmPassword)
        .then((response) => {
          setBeforeSubmit(false);
        })
        .catch((e) => {
          console.log("e");
          console.dir(e);
        });
    },
  });

  useEffect(() => {
    if (!props.isTesting) {
      if (isResetOrCreatePassword || props.isResetOrCreatePasswordTest) {
        setDataLoading(true);
        API.validateForgotPasswordLink(Key, "1")
          .then((response) => {
            setEmail(response.entity?.email);
            setDataLoading(false);
          })
          .catch((e) => {
            setDataLoading(false);
            navigate(Paths.CONTACT_US + "/rp");
          });
      } else {
        setDataLoading(true);
        API.createPasswordLinkValidation(String(IsReminder), Key, "1")
          .then((response) => {
            setEmail(response.entity?.email);
            setDataLoading(false);
          })
          .catch((e) => {
            if (e.entity?.linkValid === CREATE_PASSWORD.PASSWORD_CREATED) {
              navigate(Paths.LOGIN);
            } else {
              navigate(Paths.CONTACT_US + "/cp");
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    if (props.isTesting) {
      formik
        .setValues({
          password: "Password123@",
          confirmPassword: "Password123@",
        })
        .then(() => {
          formik.handleSubmit();
        });

      if (props.beforeSubmit) {
        setBeforeSubmit(props.beforeSubmit);
      }

      if (props.dataLoading) {
        setDataLoading(props.dataLoading);
      }

      if (props.passwordLinkExpired) {
        setPasswordLinkExpired(props.passwordLinkExpired);
      }

      disableCopyPaste({ preventDefault: () => {} });
    }
  }, []);

  const disableCopyPaste = (e: any) => {
    e.preventDefault();
  };

  return (
    <LogoTaglineChild
      whiteBoxSx={{
        height: "85%",
      }}
      testId="create-password-page"
    >
      {dataLoading && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Oval color={Colors.Blue7} secondaryColor={Colors.LightBlue1} />
        </Box>
      )}
      {!dataLoading && !passwordLinkExpired && beforeSubmit && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            // gap: 1.5,
          }}
        >
          <Text fontWeight={700} fontSize={24}>
            {isResetOrCreatePassword
              ? STRINGS.CREATE_PASSWORD.RESET_ACCOUNT
              : STRINGS.CREATE_PASSWORD.FINISH_ACCOUNT}
          </Text>
          <Box>
            <Text fontWeight={700} fontSize={18}>
              {isResetOrCreatePassword
                ? STRINGS.CREATE_PASSWORD.RESET_PASSWORD
                : STRINGS.CREATE_PASSWORD.CREATE_PASSWORD}
            </Text>
            <Text fontSize={16}>
              {STRINGS.CREATE_PASSWORD.PLEASE_CREATE_PASSWORD_FOR +
                email +
                STRINGS.CREATE_PASSWORD.DOT}
            </Text>
          </Box>

          <LabelInput
            label={STRINGS.CREATE_PASSWORD.PASSWORD}
            required={true}
            textFieldProps={{
              placeholder: STRINGS.CREATE_PASSWORD.ENTER_PASSWORD,
              sx: {
                width: 300,
                mt: 1,
              },
              type: showPassword ? "text" : "password",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid={"show-password-btn"}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
              onPaste: disableCopyPaste,
              onCut: disableCopyPaste,
              id: "password",
              name: "password",
              value: formik.values.password,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.touched.password && Boolean(formik.errors.password),
              helperText: formik.touched.password && formik.errors.password,
            }}
          />
          <LabelInput
            label={STRINGS.CREATE_PASSWORD.CONFIRM_PASSWORD}
            required={true}
            textFieldProps={{
              placeholder: STRINGS.CREATE_PASSWORD.ENTER_PASSWORD,
              sx: {
                width: 300,
                mt: 1,
              },
              type: showConfirmPassword ? "text" : "password",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid={"show-confirm-password-btn"}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Visibility
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
              onPaste: disableCopyPaste,
              onCut: disableCopyPaste,
              id: "confirmPassword",
              name: "confirmPassword",
              value: formik.values.confirmPassword,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error:
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword),
              helperText:
                formik.touched.confirmPassword && formik.errors.confirmPassword,
            }}
          />

          <Box>
            <PasswordValidationTile
              isValid={CONSTANTS.REGEX.MINIMUM_8_CHARACTER_MAXIMUM_16_CHARACTER.test(
                formik.values.password
              )}
              title={STRINGS.CREATE_PASSWORD.MAX_MIN}
            />
            <PasswordValidationTile
              isValid={CONSTANTS.REGEX.ATLEAST_ONE_UPPERCASE_AND_ONE_LOWERCASE.test(
                formik.values.password
              )}
              title={STRINGS.CREATE_PASSWORD.ONE_UPPER_ONE_LOWER}
            />
            <PasswordValidationTile
              isValid={CONSTANTS.REGEX.SPECIAL_CHARACTER.test(
                formik.values.password
              )}
              title={STRINGS.CREATE_PASSWORD.ONE_SPECIAL_CHARACTER}
            />
            <PasswordValidationTile
              isValid={CONSTANTS.REGEX.AT_LEAST_1_NUMBER.test(
                formik.values.password
              )}
              title={STRINGS.CREATE_PASSWORD.ONE_NUMERIC_CHARACTER}
            />
          </Box>
          <BlueButton
            testId="create-password-btn"
            onClick={() => formik.handleSubmit()}
            title={
              isResetOrCreatePassword
                ? STRINGS.CREATE_PASSWORD.RESET_PASSWORD
                : STRINGS.CREATE_PASSWORD.CREATE_PASSWORD
            }
            sx={{
              width: 180,
            }}
          />
        </Box>
      )}
      {!dataLoading && !passwordLinkExpired && !beforeSubmit && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Text fontWeight={700} fontSize={24}>
            {isResetOrCreatePassword
              ? STRINGS.CREATE_PASSWORD.PASSWORD_CHANGE_SUCCESS
              : STRINGS.CREATE_PASSWORD.YOUR_PASSWORD_CREATE_SUCCESS}
          </Text>

          <Text fontSize={16}>
            {STRINGS.CREATE_PASSWORD.CREATION_PASSWORD_COMPLETED}
          </Text>

          <BlueButton
            testId="login-btn"
            onClick={() => navigate(Paths.LOGIN)}
            title="Go to Login page"
            sx={{
              width: 180,
            }}
          />
        </Box>
      )}
      {!dataLoading && passwordLinkExpired && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            gap: 1.5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={500}>
            {isResetOrCreatePassword
              ? STRINGS.CREATE_PASSWORD.RESET_LINK_EXPIRED
              : STRINGS.CREATE_PASSWORD.CREATE_LINK_EXPIRED}
          </Text>
        </Box>
      )}
    </LogoTaglineChild>
  );
};

export default CreatePassword;

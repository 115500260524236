// React Imports
import { FC,useState } from 'react';

// UI Imports
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Appdetail from './Appdetail';

// Functional Imports

// Component Imports

interface TabsectionProps {
  appData?:any;
  skilldata?:any;
}

const Tabsection: FC<TabsectionProps> = (props) => {
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%', mt:2 }} className='editdetailtab'>
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange}>
          <Tab label="Application Details" value="1" />
        </TabList>
      </Box>

    <TabPanel sx={{background:'#fff',mr:2 }} value="1">
      <Appdetail appData={props.appData}/>
      </TabPanel>
    </TabContext>
  </Box>
  );
};

export default Tabsection;
import { combineReducers } from "redux";
import applicationFilterReducer from "./applicationFilterReducer";
import jobFilterReducer from "./jobFilterReducer";

const rootReducer = combineReducers({
  applicationFilter: applicationFilterReducer,
  jobFilter: jobFilterReducer,
});

export default rootReducer;

// React Imports
import React, { FC } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, SxProps } from "@mui/material";

// Functional Imports
import { Link } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import ActionTileJobList from "./ActionTileJobList";
import StatusChipJobs from "./StatusChipJobs";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import "./JobList.css";

interface JobListProps {
  testId?: string;
  disableVirtualization?: boolean;
  sx?: SxProps;
  jobListData?: any;
  jobListLoading?: boolean;
  setJobListLoading?: any;
  totalEntries?: number;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  sortModel?: any;
  setSortModel?: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
}

const JobList: FC<JobListProps> = (props) => {
  const jobListColumns = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileJobList
          {...params}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
          jobListLoading={props.jobListLoading}
          setJobListLoading={props.setJobListLoading}
        />
      ),
      minWidth: 60,
      flex: 0.5,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  return (
    <DataGrid
      data-testid="job-list-table"
      disableVirtualization={props.disableVirtualization}
      slots={{
        loadingOverlay: renderLoader,
        noRowsOverlay: renderEmptyRow,
      }}
      pagination
      sx={{
        backgroundColor: Colors.White,
        width: "calc(100vw - 140px)",
        height: "calc(100vh - 200px)",
        marginTop: 1.5,
        ml: 4,
        ...props.sx,
      }}
      loading={props.jobListLoading}
      rows={props.jobListData}
      columns={jobListColumns}
      pageSizeOptions={[5, 10, 15, 20, 50, 100]}
      paginationModel={{
        page: props.pageIndex,
        pageSize: props.pageSize,
      }}
      paginationMode="server"
      onPaginationModelChange={(ev) => {
        props.setPagination({
          pageIndex: ev.page,
          pageSize: ev.pageSize,
        });
      }}
      rowCount={props.totalEntries}
      rowSelection={false}
      sortModel={props.sortModel}
      sortingMode="server"
      onSortModelChange={(ev) => {
        props.setUpdateDataAgain(!props.updateDataAgain);
        props.setSortModel(ev);
      }}
      getRowClassName={(params: any) =>
        Functions.ValueRetrieve.getJobListRowClassName(params.row?.postingDate)
      }
    />
  );
};

export default JobList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => (
    <Text fontSize={16} fontWeight={700}>
      {params.colDef?.headerName}
    </Text>
  ),
};

const commonColumnProps = {
  disableColumnMenu: true,
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.formattedValue}</Text>;
  },
};

const columns: GridColDef[] = [
  {
    field: "referenceNumber",
    headerName: "Job ID",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={Paths.JOB + Paths.VIEW_JOB + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>{params.row?.referenceNumber}</Text>
        </Link>
      );
    },
    minWidth: 120,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "jobTitle",
    headerName: "Job Title",
    ...headerText,
    ...cellRender,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "city",
    headerName: "Job Location",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Text>
          {params.row?.city +
            ", " +
            params.row?.stateCode +
            ", " +
            params.row?.countryCode}
        </Text>
      );
    },
    minWidth: 150,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "jobType",
    headerName: "Employment Type",
    ...headerText,
    ...cellRender,
    valueGetter: (params: GridRenderCellParams) =>
      Functions.ValueRetrieve.getJobType(params.row?.jobType),
    minWidth: 150,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "jobStatus",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return <StatusChipJobs type={params.row?.jobStatus} />;
    },
    minWidth: 120,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "hiringManagerName",
    headerName: "Hiring Manager",
    ...headerText,
    ...cellRender,
    minWidth: 150,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "noOfApplications",
    headerName: "Applications",
    ...headerText,
    ...cellRender,
    minWidth: 150,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "postingDate",
    headerName: "Job Posted Date",
    valueGetter: (params) =>
      Functions.DateFunctions.formatDateObject(
        params.row?.postingDate,
        "MMM, DD, YYYY"
      ),
    ...headerText,
    ...cellRender,
    minWidth: 150,
    flex: 1,
    ...commonColumnProps,
  },
];

const renderLoader = () => <Loader />;
const renderEmptyRow = () => (
  <Box
    sx={{
      width: "100%",
      mt: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text>No data found</Text>
  </Box>
);

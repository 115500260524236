// React Imports
import { FC } from "react";

// UI Imports
import { Box, Modal } from "@mui/material";

// Functional Imports

// Component Imports

interface ResumePopupProps {
  open: boolean;
  setOpen: any;
  resumeLink?: any;

  isTesting?: boolean;
}

const ResumePopup: FC<ResumePopupProps> = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        data-testid="share-popup"
        sx={{
          border: "none",
          width: "60vw",
          height: "85vh",
        }}
      >
        <iframe
          src={props.resumeLink}
          style={{
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </Box>
    </Modal>
  );
};

export default ResumePopup;

// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { Outlet } from "react-router-dom";

// Component Imports
import SideBar from "../SideBar";
import Header from "../Header";

interface LayoutProps {}

const Layout: FC<LayoutProps> = (props) => {
  return (
    <Box
      data-testid="layout-ui"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      <SideBar />
      <Box
        sx={{
          width: "calc(100vw - 60px)",
        }}
      >
        <Header />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;

// React Imports
import { FC, useState, useEffect } from "react";

// UI Imports
import { Box, InputAdornment, Grid } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import STRINGS from "../../utils/Strings";
import Toasts from "../../utils/Toasts";
import API from "../../api/API";

// Component Imports
import LabelSelectborder from "../../common/LabelSelectborder/LabelSelectborder";
import ButtonOutlined from "../../common/ButtonOutlined";
import ButtonFilled from "../../common/ButtonFilled";
import LabelInput from "../../common/LabelInput";
import Preview from "./Preview";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

const interviewOption = [
  { label: 2, value: "Telephone" },
  { label: 4, value: "Personal" },
  { label: 8, value: "Webcam" },
  { label: 16, value: " No Interview Required" },
];

const employmentOption = [
  { label: "C", value: "Contract" },
  { label: "F", value: "Full Time" },
  { label: "R", value: "Right to hire" },
];

interface PublishjobProps {
  handleChange?: any;
  handlePrev?: any;
  formik?: any;

  isTesting?: boolean;
}

const Publishjob: FC<PublishjobProps> = (props) => {
  const [previewopen, setPreviewopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipTimeout, setZipTimeout] = useState<any>(null);

  useEffect(() => {
    if (props.isTesting) {
      handleChange({
        target: {
          name: "name",
          value: "value",
        },
      });
      handlePreview();
      handleZipchange({
        target: { value: "12345" },
      });
      handleZipchange({
        target: { value: "1" },
      });
      zipCodePopulation(1234);
      zipCodePopulation(12345);
      handleZipApiError({})
    }
  }, []);

  const handleChange = (e: any) => {
    props.formik?.setFieldValue(e.target.name, e.target.value);
  };

  const handlePreview = () => {
    props.formik.validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        setPreviewopen(true);
      } else {
        props.formik?.setTouched({
          numberOfOpenings: true,
          jobType: true,
          city: true,
          stateName: true,
          zipCode: true,
          countryName: true,
          interviewtype: true,
          billRate: true,
          salaryRange: true,
        });
      }
    });
  };

  const handleZipchange = (ev: any) => {
    let zipcode = ev.target.value.replace(/[^a-zA-Z0-9]/g, "");
    if (zipcode.length <= 6) {
      props.formik?.setFieldValue("zipCode", zipcode);

      if (zipTimeout) {
        clearTimeout(zipTimeout);
      }

      const newTimeout = setTimeout(() => {
        zipCodePopulation(zipcode);
      }, 500);

      setZipTimeout(newTimeout);
    }
  };

  const zipCodePopulation = (zipcode: number) => {
    if (String(zipcode).length >= 5) {
      setLoading(true);
      API.Zipcity(zipcode)
        .then((response) => {
          let locatindata: any =
            Functions.ValueRetrieve.geocodeResponseToCityState(response);
          if (response.status === "OK") {
            props.formik?.setFieldValue("city", locatindata?.city);
            props.formik?.setFieldValue("stateName", locatindata?.stateName);
            props.formik?.setFieldValue(
              "countryName",
              locatindata?.countryName
            );
            props.formik?.setFieldValue("stateCode", locatindata?.stateCode);
            props.formik?.setFieldValue(
              "countryCode",
              locatindata?.countryCode
            );

            props.formik?.setTouched({
              ...props.formik.touched,
              city: false,
              stateName: false,
              countryName: false,
            });
          } else if (response.status === "ZERO_RESULTS") {
            Toasts.error("No data available for this Zip.");
            props.formik?.setTouched({
              ...props.formik.touched,
              city: true,
              stateName: true,
              zipCode: true,
              countryName: true,
            });
          }
          setLoading(false);
        })
        .catch((e) => {
          handleZipApiError(e);
        });
    } else {
      props.formik?.setFieldValue("city", "");
      props.formik?.setFieldValue("stateName", "");
      props.formik?.setFieldValue("countryName", "");
      props.formik?.setFieldValue("stateCode", "");
      props.formik?.setFieldValue("countryCode", "");
      setLoading(false);
    }
  };

  const handleZipApiError = (e: any) => {
    Toasts.error(e);
    props.formik?.setFieldValue("city", "");
    props.formik?.setFieldValue("stateName", "");
    props.formik?.setFieldValue("countryName", "");
    props.formik?.setFieldValue("stateCode", "");
    props.formik?.setFieldValue("countryCode", "");

    props.formik?.setTouched({
      ...props.formik.touched,
      city: true,
      stateName: true,
      zipCode: true,
      countryName: true,
    });
    setLoading(false);
  };

  return (
    <Box
      data-testId="publish-job-component"
      sx={{
        p: 3,
        px: 20,
      }}
    >
      {loading && <Loader />}

      <Text fontWeight={600} fontSize={24}>
        {STRINGS.CREATE_JOB.PJ_HEADING_TEXT}{" "}
      </Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 2,
          textAlign: "left",
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.NUMBER_OF_OPENING}
              required={true}
              inputProps={{
                "data-testid": "no-of-openings-input",
              }}
              textFieldProps={{
                placeholder: STRINGS.CREATE_JOB.NUMBER_OF_OPENING,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                  minHeight: "75px",
                },
                id: "Number-Openings",
                name: "numberOfOpenings",
                value: props.formik?.values?.numberOfOpenings,
                onChange: (ev) => {
                  const value = ev.target.value.replace(/[^0-9]/g, "");

                  if (value.length <= 4) {
                    props.formik?.setFieldValue("numberOfOpenings", value);
                  }
                },
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.numberOfOpenings,
                  Boolean(props.formik?.errors?.numberOfOpenings)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.numberOfOpenings,
                  props.formik?.errors?.numberOfOpenings
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelSelectborder
              label={STRINGS.CREATE_JOB.EMPLOYMENT_TYPE}
              required={true}
              options={employmentOption}
              placeholder="Select"
              onChange={handleChange}
              name="jobType"
              id="jobType"
              setVal={props.formik?.values?.jobType}
              accessDisplayValue="value"
              accessValue="label"
              sx={{
                width: { xs: "100%" },
                mt: 1,
                minHeight: "75px",
                p: 0,
              }}
              handleBlur={props.formik?.handleBlur}
              error={Functions.Sonar.booleanAnd(
                props.formik?.touched?.jobType,
                Boolean(props.formik?.errors?.jobType)
              )}
              helperText={Functions.Sonar.booleanAndString(
                props.formik?.touched?.jobType,
                props.formik?.errors?.jobType
              )}
            />
          </Grid>

          {(props.formik?.values?.jobType == "C" ||
            props.formik?.values?.jobType == "R") && (
            <Grid item xs={4} md={4} mt={2}>
              <LabelInput
                label={STRINGS.CREATE_JOB.BILL_RATE}
                required={true}
                inputProps={{
                  "data-testid": "bill-rate-input",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                textFieldProps={{
                  placeholder:
                    STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.BILL_RATE,
                  sx: {
                    width: { xs: "100%" },
                    mt: 1,
                    minHeight: "75px",
                  },
                  id: "bill-rate",
                  name: "billRate",
                  value: props.formik?.values?.billRate,
                  onChange: (ev) => {
                    let regex = /^[0-9]{0,4}(\.[0-9]{0,2})?$/;
                    if (ev.target.value === "" || regex.test(ev.target.value)) {
                      props.formik?.setFieldValue("billRate", ev.target.value);
                    }
                  },
                  onBlur: props.formik?.handleBlur,
                  error: Functions.Sonar.booleanAnd(
                    props.formik?.touched?.billRate,
                    Boolean(props.formik?.errors?.billRate)
                  ),
                  helperText: Functions.Sonar.booleanAndString(
                    props.formik?.touched?.billRate,
                    props.formik?.errors?.billRate
                  ),
                }}
              />
            </Grid>
          )}

          {(props.formik?.values?.jobType == "F" ||
            props.formik?.values?.jobType == "R") && (
            <Grid item xs={4} md={4} mt={2}>
              <LabelInput
                label={STRINGS.CREATE_JOB.ANNUALSALARY}
                required={true}
                inputProps={{
                  "data-testid": "annual-salary-input",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                textFieldProps={{
                  placeholder:
                    STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.ANNUALSALARY,
                  sx: {
                    width: { xs: "100%" },
                    mt: 1,
                    minHeight: "75px",
                  },
                  id: "salaryRange",
                  name: "salaryRange",
                  value: props.formik?.values?.salaryRange,
                  onChange: (ev) => {
                    let regex = /^[0-9]{0,10}(\.[0-9]{0,2})?$/;
                    if (ev.target.value === "" || regex.test(ev.target.value)) {
                      props.formik?.setFieldValue(
                        "salaryRange",
                        ev.target.value
                      );
                    }
                  },
                  onBlur: props.formik?.handleBlur,
                  error: Functions.Sonar.booleanAnd(
                    props.formik?.touched?.salaryRange,
                    Boolean(props.formik?.errors?.salaryRange)
                  ),
                  helperText: Functions.Sonar.booleanAndString(
                    props.formik?.touched?.salaryRange,
                    props.formik?.errors?.salaryRange
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.CITY}
              required={true}
              inputProps={{
                "data-testid": "city-input",
              }}
              textFieldProps={{
                placeholder: STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.CITY,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                  minHeight: "75px",
                },
                id: "city",
                name: "city",
                value: props.formik?.values?.city,
                onChange: (ev) => {
                  props.formik?.setFieldValue("city", ev.target.value);
                },
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.city,
                  Boolean(props.formik?.errors?.city)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.city,
                  props.formik?.errors?.city
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.STATE}
              required={true}
              inputProps={{
                readOnly: true,
              }}
              textFieldProps={{
                placeholder:
                  STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.STATE,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                  minHeight: "75px",
                },
                id: "stateName",
                name: "stateName",
                value: props.formik?.values?.stateName,
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.stateName,
                  Boolean(props.formik?.errors?.stateName)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.stateName,
                  props.formik?.errors?.stateName
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            {" "}
            <LabelInput
              label={STRINGS.CREATE_JOB.ZIP}
              inputProps={{
                "data-testid": "zip-input",
              }}
              required={true}
              textFieldProps={{
                placeholder: STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.ZIP,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                  minHeight: "75px",
                },
                id: "Zip",
                name: "zipCode",
                value: props.formik?.values?.zipCode,
                onChange: (ev: any) => {
                  handleZipchange(ev);
                },
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.zipCode,
                  Boolean(props.formik?.errors?.zipCode)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.zipCode,
                  props.formik?.errors?.zipCode
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            {" "}
            <LabelInput
              label={STRINGS.CREATE_JOB.COUNTRY}
              required={true}
              inputProps={{
                readOnly: true,
              }}
              textFieldProps={{
                placeholder:
                  STRINGS.CREATE_JOB.ENTER + STRINGS.CREATE_JOB.COUNTRY,
                sx: {
                  width: { xs: "100%" },
                  mt: 1,
                  minHeight: "75px",
                },
                id: "countryName",
                name: "countryName",

                value: props.formik?.values?.countryName,
                onBlur: props.formik?.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  props.formik?.touched?.countryName,
                  Boolean(props.formik?.errors?.countryName)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  props.formik?.touched?.countryName,
                  props.formik?.errors?.countryName
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            {" "}
            <LabelSelectborder
              label={STRINGS.CREATE_JOB.INTERVIEW_MODE}
              required={true}
              accessDisplayValue="value"
              accessValue="label"
              options={interviewOption}
              placeholder="Select"
              setVal={props.formik?.values?.interviewtype}
              onChange={handleChange}
              name="interviewtype"
              sx={{
                width: { xs: "100%" },
                mt: 1,
                minHeight: "75px",
                p: 0,
              }}
              handleBlur={props.formik?.handleBlur}
              error={Functions.Sonar.booleanAnd(
                props.formik?.touched?.interviewtype,
                Boolean(props.formik?.errors?.interviewtype)
              )}
              helperText={Functions.Sonar.booleanAndString(
                props.formik?.touched?.interviewtype,
                props.formik?.errors?.interviewtype
              )}
            />
          </Grid>
          {props.formik?.values?.jobType != "R" && (
            <Grid item xs={4} md={4} mt={2}></Grid>
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
          gap: 2,
        }}
      >
        <Preview
          open={previewopen}
          setOpen={setPreviewopen}
          formik={props.formik}
          loading={loading}
        />

        <ButtonOutlined
          title={STRINGS.CREATE_JOB.BACK}
          onClick={props.handlePrev}
        />
        <ButtonFilled
          title={STRINGS.CREATE_JOB.PREVIEW}
          onClick={handlePreview}
          sx={{
            width: "auto",
            px: 2,
          }}
        />
        <ButtonFilled
          title={STRINGS.CREATE_JOB.PUBLISH}
          onClick={props.formik?.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default Publishjob;

// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, SxProps, Tooltip } from "@mui/material";
import { FaList } from "react-icons/fa";
import { VscListTree } from "react-icons/vsc";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface ChangeViewProps {
  isTesting?: boolean;
  typeOfView?: "list" | "tree";
  setTypeOfView?: any;
  sx?: SxProps;

  testId?: string;
}

const ChangeView: FC<ChangeViewProps> = (props) => {
  
  useEffect(() => {
    if (props.isTesting) {
      if (props.typeOfView) {
        props.setTypeOfView(props.typeOfView);
      }
    }
  }, []);
  

  return (
    <Box
      data-testid={props.testId}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mx: 2,
        ...props.sx,
      }}
    >
      <Text
        fontSize={16}
        style={{
          marginRight: 8,
        }}
      >
        View
      </Text>
      <Tooltip
        title={
          props.typeOfView === "tree" && "Switch listing view in list format."
        }
      >
        <IconButton
          data-testid="list-view-btn"
          onClick={() => props.setTypeOfView("list")}
          sx={{
            height: 36,
            width: 36,
            backgroundColor:
              props.typeOfView === "list" ? Colors.Blue7 : Colors.Grey8,
            borderRadius: "8px 0px 0px 8px",
            ":hover": {
              backgroundColor:
                props.typeOfView === "list" ? Colors.Blue7 : Colors.Grey8,
            },
          }}
        >
          <FaList
            color={props.typeOfView === "list" ? Colors.White : Colors.Black1}
            style={{
              height: 16,
              width: 16,
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          props.typeOfView === "list" && "Switch listing view in tree format."
        }
      >
        <IconButton
          data-testid="tree-view-btn"
          onClick={() => props.setTypeOfView("tree")}
          sx={{
            height: 36,
            width: 36,
            backgroundColor:
              props.typeOfView === "tree" ? Colors.Blue7 : Colors.Grey8,
            borderRadius: "0px 8px 8px 0px",
            ":hover": {
              backgroundColor:
                props.typeOfView === "tree" ? Colors.Blue7 : Colors.Grey8,
            },
          }}
        >
          <VscListTree
            size={20}
            color={props.typeOfView === "tree" ? Colors.White : Colors.Black1}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ChangeView;

// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface HoverActionTileProps {
  icon?: any;
  title?: string;
  onClick?: any;
  testId?: string;
}

const HoverActionTile: FC<HoverActionTileProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      data-testid={props.testId}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: 200,
        height: 40,
        p: 2,
        gap: 1,
      }}
    >
      {props.icon}
      <Text fontSize={16} fontWeight={500} color={Colors.Blue7}>
        {props.title}
      </Text>
    </ButtonBase>
  );
};

export default HoverActionTile;

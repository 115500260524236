// React Imports
import { FC } from "react";

// UI Imports
import { Box, ButtonBase, SxProps } from "@mui/material";
import Text from "../Text";
import { FaChevronRight } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";

interface BreadcrumbsProps {
  path: any[];
  sx?: SxProps;
  testId?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Box
      data-testid={props.testId}
      sx={{
        display: "flex",
        paddingTop: 2,
        alignItems: "center",
        ...props.sx,
      }}
    >
      {props.path.map((item: any, index: number) => {
        if (index === props.path.length - 1) {
          return <Text key={item}>{item.name}</Text>;
        } else {
          return (
            <>
              <ButtonBase
                data-testid={"breadcrumb-" + index}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <Text fontWeight={600} color={Colors.Blue7}>
                  {item.name}
                </Text>
              </ButtonBase>
              <FaChevronRight
                size={12}
                style={{
                  marginRight: 4,
                  marginLeft: 4,
                }}
              />
            </>
          );
        }
      })}
    </Box>
  );
};

export default Breadcrumbs;

// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";
import Text from "../../common/Text";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";

// Functional Imports

// Component Imports

interface StatusChipProps {
  type?: string;
  testId?: string;
}

const StatusChip: FC<StatusChipProps> = (props) => {
  let backgroundColor;
  let borderColor;
  let textColor;

  if (props.type === ENUMS.APPLICATION_STATUS_TYPES.PENDING_REVIEW) {
    backgroundColor = Colors.Grey4;
    borderColor = Colors.Grey3;
    textColor = Colors.Black1;
  } else if (
    props.type === ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_REQUESTED
  ) {
    borderColor = Colors.Orange;
    backgroundColor = Colors.LightOrange;
    textColor = Colors.Brown;
  } else if (props.type === ENUMS.APPLICATION_STATUS_TYPES.INTERVIEWED) {
    textColor = Colors.DarkBlue;
    borderColor = Colors.LightBlue3;
    backgroundColor = Colors.LightBlue4;
  } else if (props.type === ENUMS.APPLICATION_STATUS_TYPES.REJECTED) {
    borderColor = Colors.Red2;
    backgroundColor = Colors.LightRed;
    textColor = Colors.Red3;
  } else if (props.type === ENUMS.APPLICATION_STATUS_TYPES.HIRED) {
    borderColor = Colors.Green2;
    backgroundColor = Colors.LightGreen;
    textColor = Colors.Green4;
  } else if (
    props.type === ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_SCHEDULED
  ) {
    textColor = Colors.DarkBlue2;
    borderColor = Colors.Blue1;
    backgroundColor = Colors.LightBlue2;
  }

  return (
    <Text
      testId={props.testId}
      style={{
        backgroundColor: backgroundColor,
        border: "1px solid " + borderColor,
        padding: "2px 12px 2px 12px",
        borderRadius: 16,
      }}
      color={textColor}
    >
      {props.type}
    </Text>
  );
};

export default StatusChip;

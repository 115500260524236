// React Imports
import { FC } from "react";
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineDownload } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Functional Imports

// Component Imports

interface QuillEditorProps {
  label: string;
  required?: boolean;
  sx?: SxProps;
  value?: any | null;
  onReady?: any;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  isdelete?: boolean;
  isdownload?: boolean;
  iscopy?: boolean;
  testId?: string;
  handleDeleteClick?: any;
  disabled?: any;
  placeholder?: any;
  editorStyle?: any;
  onError?: any;
}

const QuillEditor: FC<QuillEditorProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: 1,
          }}
        >
          <Text color={Colors.Grey9}>{props.label}</Text>
          {props.required && <Text color={Colors.Red}>*</Text>}
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
          }}
        >
          {props.isdelete && (
            <Box
              sx={{ display: "flex", gap: 0.3, ml: 2, cursor: "pointer" }}
              onClick={props.handleDeleteClick}
            >
              <></>
              <RiDeleteBin6Line />
              <Text>Delete</Text>
            </Box>
          )}
          {props.isdownload && (
            <Box sx={{ display: "flex", gap: 0.3, ml: 2, cursor: "pointer" }}>
              <AiOutlineDownload />
              <Text>Download</Text>
            </Box>
          )}
          {props.iscopy && (
            <Box sx={{ display: "flex", gap: 0.3, ml: 2, cursor: "pointer" }}>
              <MdContentCopy />
              <Text>Copy</Text>
            </Box>
          )}
        </Box>
      </Box>
      <ReactQuill
        placeholder={props.placeholder}
        theme="snow"
        style={{
          ...props.editorStyle,
        }}
        value={props.value}
        onChange={(ev) => props.onChange(ev)}
      />
    </Box>
  );
};

export default QuillEditor;

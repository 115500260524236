// React Imports
import { FC, useEffect } from "react";

// UI Imports
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Oval } from "react-loader-spinner";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";
import API from "../../api/API";

// Component Imports
import ButtonOutlined from "../../common/ButtonOutlined";
import CheckboxLabel from "../../common/CheckboxLabel";
import ButtonFilled from "../../common/ButtonFilled";
import FilterName from "../../common/FilterName";
import Text from "../../common/Text";

interface FilterPopupJobsProps {
  isExpanded: boolean;
  setIsExpanded?: any;

  selectedFilter?: any;
  setSelectedFilter?: any;
  searchText?: any;
  setSearchText?: any;
  dataLoading?: any;
  setDataLoading?: any;
  jobTitleData?: any;
  setJobTitleData?: any;
  jobLocationData?: any;
  setJobLocationData?: any;
  employmentTypeData?: any;
  setEmploymentTypeData?: any;
  jobStatusData?: any;
  setJobStatusData?: any;
  hiringManagerData?: any;
  setHiringManagerData?: any;
  noOfApplicationData?: any;
  setNoOfApplicationData?: any;
  jobPostedData?: any;
  setJobPostedData?: any;
  jobTitleFilters?: any;
  setJobTitleFilters?: any;
  jobLocationFilters?: any;
  setJobLocationFilters?: any;
  employmentTypeFilters?: any;
  setEmploymentTypeFilters?: any;
  jobStatusFilters?: any;
  setJobStatusFilters?: any;
  hiringManagerFilters?: any;
  setHiringManagerFilters?: any;
  noOfApplicationFilters?: any;
  setNoOfApplicationFilters?: any;
  jobPostedFilters?: any;
  setJobPostedFilters?: any;
  onResetAll?: any;
  onApply?: any;
  anchorEl?: any;

  isTesting?: boolean;
}

const FilterPopupJobs: FC<FilterPopupJobsProps> = (props) => {
  useEffect(() => {
    let jobTitleSearch = undefined;
    let jobLocationSearch = undefined;
    let employmentTypeSearch = undefined;
    let jobStatusSearch = undefined;
    let hiringManagerSearch = undefined;
    let noOfApplicationSearch = undefined;
    let jobPostedOnSearch = undefined;

    if (
      props.selectedFilter === ENUMS.JOB_FILTERS.JOB_TITLE &&
      props.searchText !== ""
    ) {
      jobTitleSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.JOB_LOCATION &&
      props.searchText !== ""
    ) {
      jobLocationSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE &&
      props.searchText !== ""
    ) {
      employmentTypeSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.STATUS &&
      props.searchText !== ""
    ) {
      jobStatusSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.HIRING_MANAGER &&
      props.searchText !== ""
    ) {
      hiringManagerSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION &&
      props.searchText !== ""
    ) {
      noOfApplicationSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE &&
      props.searchText !== ""
    ) {
      jobPostedOnSearch = props.searchText;
    }

    props.setDataLoading(true);
    API.getJobFilters(
      jobTitleSearch,
      jobLocationSearch,
      employmentTypeSearch,
      jobStatusSearch,
      hiringManagerSearch,
      noOfApplicationSearch,
      jobPostedOnSearch
    )
      .then((response) => {
        props.setDataLoading(false);
        props.setJobTitleData(response.entity?.jobTitleFilters);
        props.setJobLocationData(response.entity?.jobLocationFilters);
        props.setEmploymentTypeData(response.entity?.employmentTypeFilters);
        props.setJobStatusData(response.entity?.jobStatusFilters);
        props.setHiringManagerData(response.entity?.hiringManagerFilters);
        props.setNoOfApplicationData(response.entity?.noOfApplicationFilters);
        props.setJobPostedData(response.entity?.jobPostedOnFilters);
      })
      .catch((e) => {
        props.setDataLoading(false);
      });
  }, [props.searchText]);

  const resetFilterForType = () => {
    if (props.selectedFilter === ENUMS.JOB_FILTERS.JOB_TITLE) {
      props.setJobTitleFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.JOB_LOCATION) {
      props.setJobLocationFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE) {
      props.setEmploymentTypeFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.STATUS) {
      props.setJobStatusFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.HIRING_MANAGER) {
      props.setHiringManagerFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION) {
      props.setNoOfApplicationFilters([]);
    } else if (props.selectedFilter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE) {
      props.setJobPostedFilters([]);
    }
  };

  const id = props.isExpanded ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.isExpanded}
      anchorEl={props.anchorEl}
      onClose={() => props.setIsExpanded(false)}
    >
      <Box
        data-testid="filters-jobs-popup"
        sx={{
          width: 370,
          top: 160,
          zIndex: 10,
          left: 100,
          backgroundColor: Colors.White,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            Filters
          </Text>
          <IconButton
            onClick={() => props.setIsExpanded(false)}
            data-testid="close-modal-btn"
          >
            <IoClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 300,
              width: "45%",
              backgroundColor: Colors.Grey11,
            }}
          >
            <FilterName
              isActive={props.selectedFilter === ENUMS.JOB_FILTERS.JOB_TITLE}
              title="Job Title"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.JOB_TITLE)
              }
              testId="job-title-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.JOB_FILTERS.JOB_LOCATION}
              title="Job Location"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.JOB_LOCATION)
              }
              testId="job-location-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE
              }
              title="Employment Type"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE)
              }
              testId="employment-type-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.JOB_FILTERS.STATUS}
              title="Status"
              onClick={() => props.setSelectedFilter(ENUMS.JOB_FILTERS.STATUS)}
              testId="status-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.JOB_FILTERS.HIRING_MANAGER
              }
              title="Hiring Manager"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.HIRING_MANAGER)
              }
              testId="hiring-manager-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION
              }
              title="No of Application"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.NO_OF_APPLICATION)
              }
              testId="no-of-application-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE
              }
              title="Job Posted Date"
              onClick={() =>
                props.setSelectedFilter(ENUMS.JOB_FILTERS.JOB_POSTED_DATE)
              }
              testId="job-posted-date-filter-btn"
            />
          </Box>
          <Box
            sx={{
              width: "55%",
              height: 300,
              backgroundColor: Colors.White,
              // border: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                margin="dense"
                sx={{
                  paddingRight: 0,
                  fontFamily: "Roboto",
                  backgroundColor: Colors.White,
                  "& .MuiInputBase-root": { height: "34px" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMdSearch size={20} />
                    </InputAdornment>
                  ),
                }}
                value={props.searchText}
                onChange={(ev) => {
                  props.setSearchText(ev.target.value);
                }}
              />
              <IconButton
                data-testid="search-empty-btn"
                onClick={() => resetFilterForType()}
                sx={{
                  height: 32,
                  width: 32,
                  mt: "4px",
                  borderRadius: 0.5,
                  border: "1px solid " + Colors.Grey10,
                }}
              >
                <GrPowerReset />
              </IconButton>
            </Box>
            <Box
              sx={{
                // border: 2,
                overflowY: "auto",
                height: 260,
              }}
            >
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.JOB_TITLE &&
                props.jobTitleData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="job-title-checkbox"
                    checked={props.jobTitleFilters.includes(item)}
                    onChange={() => {
                      if (props.jobTitleFilters.includes(item)) {
                        const modifiedArr = props.jobTitleFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setJobTitleFilters([...modifiedArr]);
                      } else {
                        props.setJobTitleFilters([
                          ...props.jobTitleFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.JOB_LOCATION &&
                props.jobLocationData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="job-location-checkbox"
                    checked={props.jobLocationFilters.includes(item)}
                    onChange={() => {
                      if (props.jobLocationFilters.includes(item)) {
                        const modifiedArr = props.jobLocationFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setJobLocationFilters([...modifiedArr]);
                      } else {
                        props.setJobLocationFilters([
                          ...props.jobLocationFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE &&
                props.employmentTypeData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={Functions.ValueRetrieve.getJobType(item)}
                    checkBoxTestId="employment-type-checkbox"
                    checked={props.employmentTypeFilters.includes(item)}
                    onChange={() => {
                      if (props.employmentTypeFilters.includes(item)) {
                        const modifiedArr = props.employmentTypeFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setEmploymentTypeFilters([...modifiedArr]);
                      } else {
                        props.setEmploymentTypeFilters([
                          ...props.employmentTypeFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.STATUS &&
                props.jobStatusData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="status-checkbox"
                    checked={props.jobStatusFilters.includes(item)}
                    onChange={() => {
                      if (props.jobStatusFilters.includes(item)) {
                        const modifiedArr = props.jobStatusFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setJobStatusFilters([...modifiedArr]);
                      } else {
                        props.setJobStatusFilters([
                          ...props.jobStatusFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.HIRING_MANAGER &&
                props.hiringManagerData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="hiring-manager-checkbox"
                    checked={props.hiringManagerFilters.includes(item)}
                    onChange={() => {
                      if (props.hiringManagerFilters.includes(item)) {
                        const modifiedArr = props.hiringManagerFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setHiringManagerFilters([...modifiedArr]);
                      } else {
                        props.setHiringManagerFilters([
                          ...props.hiringManagerFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION &&
                props.noOfApplicationData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="no-of-application-checkbox"
                    checked={props.noOfApplicationFilters.includes(item)}
                    onChange={() => {
                      if (props.noOfApplicationFilters.includes(item)) {
                        const modifiedArr = props.noOfApplicationFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setNoOfApplicationFilters([...modifiedArr]);
                      } else {
                        props.setNoOfApplicationFilters([
                          ...props.noOfApplicationFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE &&
                props.jobPostedData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="job-posted-date-checkbox"
                    checked={props.jobPostedFilters.includes(item)}
                    onChange={() => {
                      if (props.jobPostedFilters.includes(item)) {
                        const modifiedArr = props.jobPostedFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setJobPostedFilters([...modifiedArr]);
                      } else {
                        props.setJobPostedFilters([
                          ...props.jobPostedFilters,
                          item,
                        ]);
                      }
                    }}
                  />
                ))}
              {!props.dataLoading &&
                ((props.selectedFilter === ENUMS.JOB_FILTERS.JOB_TITLE &&
                  props.jobTitleData.length === 0) ||
                  (props.selectedFilter === ENUMS.JOB_FILTERS.JOB_LOCATION &&
                    props.jobLocationData.length === 0) ||
                  (props.selectedFilter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE &&
                    props.employmentTypeData.length === 0) ||
                  (props.selectedFilter === ENUMS.JOB_FILTERS.STATUS &&
                    props.jobStatusData.length === 0) ||
                  (props.selectedFilter === ENUMS.JOB_FILTERS.HIRING_MANAGER &&
                    props.hiringManagerData.length === 0) ||
                  (props.selectedFilter ===
                    ENUMS.JOB_FILTERS.NO_OF_APPLICATION &&
                    props.noOfApplicationData.length === 0) ||
                  (props.selectedFilter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE &&
                    props.jobPostedData.length === 0)) && (
                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      mt: 8,
                    }}
                  >
                    <Text>No Data</Text>
                  </Box>
                )}
              {props.dataLoading && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Oval
                    width={40}
                    height={40}
                    color={Colors.Blue7}
                    secondaryColor={Colors.LightBlue1}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 60,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            px: 2,
            gap: 2,
          }}
        >
          <ButtonOutlined title="Reset All" onClick={props.onResetAll} />
          <ButtonFilled title="Apply" onClick={props.onApply} />
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopupJobs;

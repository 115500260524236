// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase, Switch } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";

// Component Imports
import ApplicationList from "./ApplicationList";
import FiltersPopup from "./FiltersPopup";
import RejectPopup from "./RejectPopup";
import Breadcrumbs from "../../common/Breadcrumbs";
import ResumePopup from "./ResumePopup";
import FilterTile from "../../common/FilterTile";
import SharePopup from "./SharePopup";
import HirePopup from "./HirePopup";
import Text from "../../common/Text";
import ActionTypes from "../../redux/action";
import ModalForProposeTimeSlot from "./ModalForProposeTimeSlot";
import ChangeView from "../../common/ChangeView";
import ApplicationHierarchy from "./ApplicationHierarchy";
import ActiveJobsSwitch from "../../common/ActiveJobsSwitch";

interface ApplicationsProps {
  isTesting?: boolean;
}

const Applications: FC<ApplicationsProps> = (props) => {
  const applicationFilter = useSelector(
    (state: any) => state.applicationFilter
  );
  const dispatch = useDispatch();

  // List Related
  const [applicationsList, setApplicationsList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [pagination, setPagination] = useState(applicationFilter.pagination);
  const [sortModel, setSortModel] = useState<any>(applicationFilter.sortModel);
  const [applicationCount, setApplicationCount] = useState(0);

  // Filter Related
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState<any>(ENUMS.FILTERS.NAME);
  const [searchText, setSearchText] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const [nameData, setNameData] = useState<any>(applicationFilter.nameData);
  const [locationData, setLocationData] = useState(
    applicationFilter.locationData
  );
  const [statusData, setStatusData] = useState(applicationFilter.statusData);
  const [jobData, setJobData] = useState(applicationFilter.jobData);
  const [experienceData, setExperienceData] = useState(
    applicationFilter.experienceData
  );

  const [nameFilters, setNameFilters] = useState<any>(
    applicationFilter.nameFilters
  );
  const [locationFilters, setLocationFilters] = useState<any>(
    applicationFilter.locationFilters
  );
  const [statusFilters, setStatusFilters] = useState<any>(
    applicationFilter.statusFilters
  );
  const [jobFilters, setJobFilters] = useState<any>(
    applicationFilter.jobFilters
  );
  const [experienceFilters, setExperienceFilters] = useState<any>(
    applicationFilter.experienceFilters
  );

  const [bookmarkedEnabled, setBookmarkedEnabled] = useState(
    applicationFilter.bookmarkedEnabled
  );
  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  // Actions Related
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [shareOpen, setShareOpen] = useState(false);
  const [shareDetails, setShareDetails] = useState();

  const [resumeOpen, setResumeOpen] = useState(false);
  const [resumeLink, setResumeLink] = useState("");

  const [hireOpen, setHireOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);

  const [scheduleInterviewOpen, setScheduleInterviewOpen] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [slotId, setSlotId] = useState<string[]>([]);
  const [candidateName, setCandidateName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [resumeVLNStatus, setResumeVLNStatus] = useState("");
  const [submissionId, setSubmissionId] = useState("");

  const [typeOfView, setTypeOfView] = useState<"list" | "tree">("list");

  useEffect(() => {
    if (props.isTesting) {
      getApplicationList();
      onResetAll();
      onApply();
      updateFilterKeys();
      onClearAllFilters();
      handleClick({});
      removeFilter(ENUMS.FILTERS.NAME);
      removeFilter(ENUMS.FILTERS.EXPERIENCE);
      removeFilter(ENUMS.FILTERS.JOB_ID);
      removeFilter(ENUMS.FILTERS.LOCATION);
      removeFilter(ENUMS.FILTERS.STATUS);
      onShare({});
      onHire({});
      onReject({});
      onResumeOpen({});
    }
  }, []);

  useEffect(() => {
    getApplicationList();
    updateFilterKeys();
    updateRedux();
  }, [updateDataAgain, pagination]);

  const getApplicationList = () => {
    setTableLoading(true);

    let sortField = "";
    let sortOrder = "Descending";

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = "Ascending";
    }

    if (sortModel?.[0]?.field === "candidateName") {
      sortField = "candidateName";
    } else if (sortModel?.[0]?.field === "location") {
      sortField = "location";
    } else if (sortModel?.[0]?.field === "jobReference") {
      sortField = "jobId";
    } else if (sortModel?.[0]?.field === "status") {
      sortField = "status";
    } else if (sortModel?.[0]?.field === "experience") {
      sortField = "experience";
    }

    const payload = {
      name: nameFilters,
      location: locationFilters,
      statuses: statusFilters,
      jobs: jobFilters,
      experiences: experienceFilters,
      isBookmarked: bookmarkedEnabled,
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortColumn: sortField,
      sortOrder: sortOrder,
    };

    API.getApplicationsNew(payload)
      .then((response) => {
        if (response.entityList) {
          setApplicationsList(response.entityList);
        } else {
          setApplicationsList([]);
        }
        setTableLoading(false);
        setApplicationCount(Number(response.totalRecords));
      })
      .catch((e) => {
        setTableLoading(false);
      });
  };

  const updateRedux = () => {
    const updatedState = {
      pagination: pagination,
      sortModel: sortModel,
      nameData: nameData,
      locationData: locationData,
      statusData: statusData,
      jobData: jobData,
      experienceData: experienceData,
      nameFilters: nameFilters,
      locationFilters: locationFilters,
      statusFilters: statusFilters,
      jobFilters: jobFilters,
      experienceFilters: experienceFilters,
      bookmarkedEnabled: bookmarkedEnabled,
    };

    dispatch({
      type: ActionTypes.UPDATE_APPLICATION_STATE,
      payload: updatedState,
    });
  };

  const onResetAll = () => {
    setNameFilters([]);
    setLocationFilters([]);
    setStatusFilters([]);
    setJobFilters([]);
    setExperienceFilters([]);
  };

  const onApply = () => {
    setUpdateDataAgain(!updateDataAgain);
    setFiltersOpen(false);
  };

  const removeFilter = (filter: string) => {
    if (filter === ENUMS.FILTERS.NAME) {
      setNameFilters([]);
    } else if (filter === ENUMS.FILTERS.LOCATION) {
      setLocationFilters([]);
    } else if (filter === ENUMS.FILTERS.STATUS) {
      setStatusFilters([]);
    } else if (filter === ENUMS.FILTERS.EXPERIENCE) {
      setExperienceFilters([]);
    } else if (filter === ENUMS.FILTERS.JOB_ID) {
      setJobFilters([]);
    }
    setUpdateDataAgain(!updateDataAgain);
  };

  const updateFilterKeys = () => {
    let selectedFiltersForRequestPass = [];

    if (nameFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.FILTERS.NAME);
    }
    if (locationFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.FILTERS.LOCATION);
    }
    if (statusFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.FILTERS.STATUS);
    }
    if (experienceFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.FILTERS.EXPERIENCE);
    }
    if (jobFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.FILTERS.JOB_ID);
    }

    if (selectedFiltersForRequestPass.length > 0) {
      setSelectedFiltersForRequest([...selectedFiltersForRequestPass]);
    } else {
      setSelectedFiltersForRequest([]);
    }
  };

  const onClearAllFilters = () => {
    onResetAll();
    setUpdateDataAgain(!updateDataAgain);
  };

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  const onShare = (row: any) => {
    setShareDetails(row);
    setShareOpen(true);
  };

  const onHire = (row: any) => {
    setShareDetails(row);
    setHireOpen(true);
  };

  const onReject = (row: any) => {
    setShareDetails(row);
    setRejectOpen(true);
  };

  const onResumeOpen = (link: any) => {
    setResumeOpen(true);
    setResumeLink(link);
  };

  const onScheduleInterviewOpen = (
    resumeVLNStatus: string,
    currentAvailabilities: any,
    candidateName: string,
    candidateEmail: string,
    id: string
  ) => {
    setCandidateName(candidateName);
    setResumeVLNStatus(resumeVLNStatus);
    setCandidateEmail(candidateEmail);
    setSubmissionId(id);
    if (resumeVLNStatus === "Interview Requested" || resumeVLNStatus === "Interview Scheduled") {
      const activeAvailabilities = currentAvailabilities?.filter(
        (availability: any) => availability.isActive
      );
      const slotId = currentAvailabilities?.filter(
        (availability: any) => availability.id
      );

      if (activeAvailabilities?.length > 0) {
        setSlotId(slotId);
        setAvailabilities(activeAvailabilities?.[0]?.currentAvailabilities);
        setDescription(activeAvailabilities?.[0]?.description);
      }
    }

    setScheduleInterviewOpen(true);
  };

  return (
    <Box
      data-testid="applications-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          pt: 2,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
            mt: 1,
            mb: 0.5,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { name: "Applications" },
          ]}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiltersPopup
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={dataLoading}
            setDataLoading={setDataLoading}
            nameData={nameData}
            setNameData={setNameData}
            locationData={locationData}
            setLocationData={setLocationData}
            statusData={statusData}
            setStatusData={setStatusData}
            jobData={jobData}
            setJobData={setJobData}
            experienceData={experienceData}
            setExperienceData={setExperienceData}
            nameFilters={nameFilters}
            setNameFilters={setNameFilters}
            locationFilters={locationFilters}
            setLocationFilters={setLocationFilters}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            jobFilters={jobFilters}
            setJobFilters={setJobFilters}
            experienceFilters={experienceFilters}
            setExperienceFilters={setExperienceFilters}
            bookmarkedEnabled={bookmarkedEnabled}
            setBookmarkedEnabled={setBookmarkedEnabled}
            anchorEl={anchorEl}
            onApply={onApply}
            removeFilter={removeFilter}
            onClearAllFilters={onClearAllFilters}
          />
          <ButtonBase
            data-testid="filter-toggle-btn"
            onClick={(ev) => handleClick(ev)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              py: 0.5,
              mr: 2,
            }}
          >
            <Text fontWeight={700} fontSize={16}>
              Filters
            </Text>
            <FaAngleDown
              size={18}
              style={{
                marginTop: 2,
              }}
            />
          </ButtonBase>
          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              title={item}
              onClick={() => removeFilter(item)}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ButtonBase
              onClick={onClearAllFilters}
              sx={{
                p: 0.5,
              }}
              data-testid="clear-all-filters-btn"
            >
              <Text fontWeight={700} fontSize={16}>
                Clear All Filters
              </Text>
            </ButtonBase>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActiveJobsSwitch label="Active Jobs" />
          <ChangeView
            sx={{
              mr: 5,
            }}
            typeOfView={typeOfView}
            setTypeOfView={setTypeOfView}
          />
        </Box>
      </Box>

      {(true || typeOfView === "list") && (
        <ApplicationList
          tableData={applicationsList}
          tableLoading={tableLoading}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPagination={setPagination}
          totalEntries={applicationCount}
          sortModel={sortModel}
          setUpdateDataAgain={setUpdateDataAgain}
          updateDataAgain={updateDataAgain}
          setSortModel={setSortModel}
          onShare={onShare}
          onHire={onHire}
          onReject={onReject}
          onResumeOpen={onResumeOpen}
          onScheduleInterviewOpen={onScheduleInterviewOpen}
        />
      )}
      {false && typeOfView === "tree" && <ApplicationHierarchy />}
      <HirePopup
        open={hireOpen}
        applicationDetails={shareDetails}
        setOpen={setHireOpen}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <RejectPopup
        open={rejectOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setOpen={setRejectOpen}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <SharePopup
        open={shareOpen}
        shareDetails={shareDetails}
        setOpen={setShareOpen}
      />
      <ResumePopup
        open={resumeOpen}
        resumeLink={resumeLink}
        setOpen={setResumeOpen}
      />
      <ModalForProposeTimeSlot
        scheduleInterviewOpen={scheduleInterviewOpen}
        setScheduleInterviewOpen={setScheduleInterviewOpen}
        setAvailabilities={setAvailabilities}
        availabilities={availabilities}
        candidateName={candidateName}
        candidateEmail={candidateEmail}
        slotId={slotId}
        setSlotId={setSlotId}
        description={description}
        resumeVLNStatus={resumeVLNStatus}
        setDescription={setDescription}
        id={submissionId}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default Applications;

// React Imports
import { FC, useState,useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// UI Imports
import {
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";
import STRINGS from "../../utils/Strings";
import Tabsection from "./Tabsection";
import Loader from '../../common/Loader';
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
// Component Imports
import Breadcrumbs from "../../common/Breadcrumbs";
import Text from "../../common/Text";
import { IoMdSearch } from "react-icons/io";
import ButtonFilled from "../../common/ButtonFilled";
import Functions from "../../utils/Functions";

// Functional Imports

// Component Imports

interface ViewJobProps {}

const ViewJob: FC<ViewJobProps> = (props) => {
  const Params = useParams();
  const navigate = useNavigate();
  const [jobData,setJobData]=useState<any>({});
  const [skilldata,setSkilldata]=useState<any>({});

  const [loading, setLoading] = useState(false);
  const jobID = Params.jobid
  useEffect(()=>{
    setLoading(true)
    API.getJobByID(jobID)
      .then((response) => {
        if(response?.status===200){
          setJobData(response?.entity)
          setSkilldata(Functions.ValueRetrieve.getSkillandLicenses(response?.entity?.manualData))
        }
        setLoading(false)
      })
      .catch((e) => {
        Toasts.error(e);
        setJobData({})
        setLoading(false)
      });
  },[jobID])
  let postingdate = Functions.DateFunctions.formatDateObject(
    jobData.postingDate,
    "MMM DD YYYY"
  )

  return (
    <Box
      data-testid="view-job-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.APPLICATIONS, name: "Home" },
            { path: Paths.JOBS, name: "Jobs" },
            { name: jobData.referenceNumber },
          ]}
        />
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search"
          sx={{
            paddingRight: 0,
            marginRight: 1.5,
            width: 300,
            fontFamily: "Roboto",
            backgroundColor: Colors.White,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoMdSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.COMMONSTR.DETAIL}
        </Text>
        <ButtonFilled
          title={STRINGS.COMMONSTR.EDIT}
          onClick={() => {
            navigate(Paths.JOB + Paths.EDIT_JOB + "/" + Params.jobid);
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          ml: 4,
          mr: 4,
          p: 2,
          pl: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            //   alignItem: "center",
            //   justifyContent: "center"
          }}
        >
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_ID}
            </Text>
            <Text fontWeight={700} fontSize={14}>
            {jobData.referenceNumber}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.HIRING_MANAGER}
            </Text>
            <Text fontWeight={700} fontSize={14}>
            {jobData?.hiringManagerName}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.COMMONSTR.STATUS}
            </Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flax-start",
                alignItems: "center",
                p: 0,
              }}
            >
              <div className={jobData.jobStatus}>
                <Text fontWeight={400} fontSize={14}>
                {jobData.jobStatus}
                </Text>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_LOCATION}
            </Text>
            <Text fontWeight={700} fontSize={14}>
               {jobData.city},  {jobData.stateCode}, {jobData.countryCode}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.CREATE_JOB.JOB_POSTED}
            </Text>
            <Text fontWeight={700} fontSize={14}>
            {postingdate}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          pl: 4,
          pt: 0,
        }}
      >
        <Tabsection jobdata={jobData } skilldata ={skilldata} appjobid={jobData.stafflineJobId}/>
      </Box>
    </Box>
  );
};

export default ViewJob;

// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps, TextFieldProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import "react-tagsinput/react-tagsinput.css"; // default styles
import TagsInput from "react-tagsinput";

// Component Imports
import Text from "../Text";

interface LabelInputChipsetProps {
  label: string;
  required?: boolean;
  sx?: SxProps;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  testId?: string;
  onChange?: any;
  tags?: any;
  maxLength?: number;
}

const LabelInputChipset: FC<LabelInputChipsetProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
          mb:1,
          mt:0,
        }}
      >
        <Text color={Colors.Grey9}>{props.label}</Text>
        {props.required && <Text color={Colors.Red}>*</Text>}
      </Box>
      <TagsInput
        value={props.tags}
        onChange={props.onChange}        
        inputProps={{
          placeholder: props.tags.length === 0 ? props.placeholder : '',
          className: "react-tagsinput-input CJ-skill",
          maxLength: props.maxLength,
        }}
      />
    </Box>
  );
};

export default LabelInputChipset;

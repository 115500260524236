// React Imports
import { FC } from "react";

// UI Imports
import { Backdrop, Box } from "@mui/material";
import "./Loader.css";

// Functional Imports
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";

// Component Imports

interface LoaderProps {}

const Loader: FC<LoaderProps> = (props) => {
  return (
    <Backdrop sx={{ zIndex: 9999, backgroundColor: Colors.Grey13 }} open={true}>
      <Box className="loading" component={"img"} src={Images.AnimatedLoader} />
    </Backdrop>
  );
};

export default Loader;

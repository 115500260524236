const BASEURL = process.env.REACT_APP_BASEURL;
const SERVER = {
  V4: BASEURL + "v4",
  NEW: BASEURL + "jobProvider-api/api",
  GOOGLEAPI: BASEURL + "jobProvider-api/api/Jobs/get-location",
  JOBPROVIDER: BASEURL + "jobProvider-api/api",
  INTEGRATION_API: BASEURL + "integration-api/api",
  IDPROVIDER_API: BASEURL + "idProvider-api/api",
  CANDIDATE_API: BASEURL + "candidate-api/api",
};

const ENDPOINTS = {
  ACCOUNTS: {
    LOGIN: SERVER.IDPROVIDER_API + "/SignIn/vln-login",
    CHECK_CREATEPASSWORD_LINKVALIDATION:
      SERVER.IDPROVIDER_API + "/SignIn/check-createpassword-linkvalidation",
    EMAIL_VALIDATION: SERVER.IDPROVIDER_API + "/SignIn/email-validation",
    VALIDATE_FORGOTPASSWORD_LINK:
      SERVER.IDPROVIDER_API + "/SignIn/validate-forgotpassword-link",
    RESETPASSWORD: SERVER.IDPROVIDER_API + "/SignIn/resetpassword",
  },
  PROPOSE_TIME_SLOTS: {
    INTERVIEW_SLOTS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/interview-availability",
  },
  APPLICATIONS: {
    APPLICATIONS: SERVER.V4 + "/applications",
    APPLICATION_FILTER_DATA:
      SERVER.V4 + "/applications/application-filter-data",
    GETAPPLICATION: SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmission",
  },
  JOBS: {
    JOBS: SERVER.NEW + "/Jobs/",
    UPDATE_JOB_STATUS_POSTFIX: "/update-job-status",
    GETJOB: SERVER.NEW + "/Jobs/GetJob",
    JOB_FILTER_DATA: SERVER.NEW + "/Jobs/job-filter-data",
    INTERVIEW_SLOTS: SERVER.V4 + "/applications/interview-slots",
    JOB_APPLICATION:
      SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmissions",
    JOB_BY_STAFFLINE_ID: SERVER.JOBPROVIDER + "/Jobs/job-by-stafflineid/",
  },
  JOB: {
    CREATEJOB: SERVER.JOBPROVIDER + "/Jobs/add",
    UPDATEJOB: SERVER.JOBPROVIDER + "/Jobs/update",
    ENHANCE_JD: SERVER.V4 + "/jobs/create-enhance-job-description",
    ZIPCODE: SERVER.GOOGLEAPI,
    GOOGLEAPI_KEY: process.env.REACT_APP_GOOGKEAPI_KEY,
    GETJOBBYID: SERVER.JOBPROVIDER + "/Jobs",
  },
  MASTERS: {
    ZIPCITY: SERVER.V4 + "/masters/city-state-country",
    INTERVIEWTYPE: SERVER.V4 + "/masters/interview-types",
    EMPLOYMENTTYPE: SERVER.V4 + "/masters/assignment-types",
  },
  EXIMIUS: {
    JOB_DETAILS: SERVER.INTEGRATION_API + "/Eximius/JobDetails",
    RESET_COUNT: SERVER.INTEGRATION_API + "/Eximius/delete-api_counter",
    GET_COUNT:SERVER.INTEGRATION_API + "/Eximius/exmius-attempts",
  },
  CANDIDATE_SUBMISSION: {
    GET_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmissions",
    SUBMISSIONS_FILTER_DATA:
      SERVER.CANDIDATE_API + "/CandidateSubmission/submissions-filter-data",
    SHARE_APPLICATION:
      SERVER.CANDIDATE_API + "/CandidateSubmission/share_application",
    BOOKMARK_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/bookmark-submissions",
    DE_BOOKMARK_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/de-bookmark-submissions",
    HIRE: SERVER.CANDIDATE_API + "/CandidateSubmission/hire",
    REJECT: SERVER.CANDIDATE_API + "/CandidateSubmission/Reject",
    RESUME: SERVER.CANDIDATE_API + "/CandidateSubmission/resume",
  },
};

export default ENDPOINTS;

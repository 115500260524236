// React Imports
import { FC } from "react";

// UI Imports
import { Paper } from "@mui/material";
import { MdLogout } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Component Imports
import ProfileActionTile from "./ProfileActionTile";

interface ProfileActionsProps {}

const ProfileActions: FC<ProfileActionsProps> = (props) => {
  return (
    <Paper
      data-testid="profile-actions-ui"
      sx={{
        position: "absolute",
        // height: 360,
        width: 300,
        right: 30,
        top: 60,
        zIndex: 2,
      }}
    >
      <ProfileActionTile
        icon={<CgProfile size={24} color={Colors.Black1} />}
        label="Profile"
      />
      <ProfileActionTile
        testId="logout-btn"
        icon={<MdLogout size={24} color={Colors.Red} />}
        label="Logout"
        textColor={Colors.Red}
        onClick={() => {
          localStorage.removeItem("JWT_TOKEN");
          localStorage.removeItem("USER_ID");
          localStorage.removeItem("Remaining_Attemts");
          window.location.reload();
        }}
      />
    </Paper>
  );
};

export default ProfileActions;

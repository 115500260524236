// React Imports
import { FC, ReactElement, cloneElement } from "react";

// UI Imports
import { Box, Button, SxProps, Tooltip } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports

interface SideBarTileProps {
  icon: ReactElement;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isGroup?: boolean;
  open?: boolean;
  isActive?: boolean;
  testId?: string;
  sx?: SxProps;
}

const SideBarTile: FC<SideBarTileProps> = (props) => {
  return (
    <Tooltip 
      title={props.label} 
      placement="right" 
      arrow 
      slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}>
      <Button
        data-testid={props.testId}
        onClick={props.onClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 40,
          textTransform: "none",
          p: 3,
          borderRadius: 0,
          backgroundColor: props.isActive ? Colors.White : Colors.Blue7,
          ":hover": {
            backgroundColor: Colors.White + "11",
          },
          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cloneElement(props.icon, {
            color: props.isActive ? Colors.Blue7 : Colors.White,
          })}
        </Box>
      </Button>
    </Tooltip>
  );
};

export default SideBarTile;

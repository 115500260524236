// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

// Functional Imports
import Text from "../../common/Text";

// Component Imports
import Colors from "../../utils/Colors";

interface PasswordValidationTileProps {
  isValid?: boolean;
  title?: string;
  sx?: SxProps;
}

const PasswordValidationTile: FC<PasswordValidationTileProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        ...props.sx,
      }}
    >
      {props.isValid && <FaCheckCircle size={14} color={Colors.Green} />}
      {!props.isValid && (
        <MdCancel
          style={{
            marginLeft: -1.5,
          }}
          size={16}
          color={Colors.Red}
        />
      )}
      <Text fontSize={12}>{props.title}</Text>
    </Box>
  );
};

export default PasswordValidationTile;
